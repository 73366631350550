import {
  AD_HOC_MESSAGE_SLIDEOUT,
  CANCEL_INTERVIEW_SLIDEOUT,
  CANCEL_TASK_SLIDEOUT,
  COMPLETE_AND_THANKS,
  FUND_STUDY,
  MANUAL_RESCHEDULE_MODAL,
  MANUAL_SCHEDULE_SLIDEOUT,
  RESCHEDULE_REQUEST_SLIDEOUT,
  SEND_INVITED_REMINDER,
  SEND_INVITES,
  SEND_REMINDERS,
  SEND_SCREENER,
  SEND_THANKS,
  StudyAction,
  UPDATE_MODERATOR
} from '@components/StudiesApp/components/StudyPublished/components/ACTIONS';
import {
  CancelSlideOut,
  CancelTaskSlideOut,
  GenericMessageSlideOut,
  ManualScheduleSlideOut,
  ParticipationsInviteSlideOut,
  ParticipationsScreenerSlideOut,
  ThanksSlideOut
} from '@components/StudyMessages';
import { ChangeIncentiveModal, useStudyFunder } from '@components/Wallet';
import { UpdateModeratorModal } from '@components/StudiesApp/components/StudyPublished/components/UpdateModeratorModal';
import { ManualRescheduleModal } from '@components/StudiesApp/components/StudyPublished/components/ManualRescheduleModal';
import * as React from 'react';
import { ServerFilterQuery } from 'components/CandidatesApp/CandidatesIndex/types';

interface Props {
  mode: StudyAction | null;
  study: Study;
  participations: Participation[];
  onClose: () => void;
  onSuccess: (backgroundTask?: BackgroundTask, mode?: StudyAction | null) => void;
  allSelected?: boolean;
  query?: ServerFilterQuery;
}

export const EmailSlideOuts: React.FC<Props> = ({
  query,
  mode,
  study,
  participations,
  onClose,
  onSuccess,
  allSelected
}) => {
  const funder = useStudyFunder({ study });

  const handleSuccess = (backgroundTask?: BackgroundTask) => {
    onSuccess(backgroundTask, mode);
  };

  return (
    <>
      {mode === AD_HOC_MESSAGE_SLIDEOUT && (
        <GenericMessageSlideOut
          participations={participations}
          allSelected={allSelected}
          query={query}
          title='Ad hoc message'
          study={study}
          cta='Send message'
          event='ad_hoc'
          onClose={onClose}
          onSuccess={handleSuccess}
        />
      )}

      {mode === SEND_INVITES && (
        <ParticipationsInviteSlideOut
          allSelected={allSelected}
          study={study}
          participationsIds={participations.map((p) => p.id)}
          onClose={onClose}
          onSuccess={handleSuccess}
          query={query}
        />
      )}

      {mode === SEND_SCREENER && (
        <ParticipationsScreenerSlideOut
          allSelected={allSelected}
          query={query}
          study={study}
          participations={participations}
          onClose={onClose}
          onSuccess={handleSuccess}
        />
      )}
      {mode === FUND_STUDY && (
        <ChangeIncentiveModal
          onConfirm={handleSuccess}
          limitCount={study.maximum_slots}
          study={study}
          funder={funder}
          onCancel={onClose}
        />
      )}
      {mode === SEND_INVITED_REMINDER && (
        <GenericMessageSlideOut
          allSelected={allSelected}
          query={query}
          title='Send invitation reminder'
          study={study}
          participations={participations}
          cta='Send reminder'
          event='invited_reminder'
          onClose={onClose}
          onSuccess={handleSuccess}
        />
      )}
      {mode === SEND_REMINDERS && (
        <GenericMessageSlideOut
          allSelected={allSelected}
          query={query}
          title='Send reminder'
          study={study}
          participations={participations}
          cta='Send reminder'
          event={`${study.moderated ? 'booked' : 'started'}_reminder` as StudyMessageEvent}
          onClose={onClose}
          onSuccess={handleSuccess}
        />
      )}
      {mode === SEND_THANKS && (
        <ThanksSlideOut
          allSelected={allSelected}
          query={query}
          messageOnly
          study={study}
          parties={participations}
          onClose={onClose}
          onSuccess={handleSuccess}
        />
      )}
      {mode === COMPLETE_AND_THANKS && (
        <ThanksSlideOut
          allSelected={allSelected}
          query={query}
          study={study}
          parties={participations}
          onClose={onClose}
          onSuccess={handleSuccess}
        />
      )}
      {mode === RESCHEDULE_REQUEST_SLIDEOUT && (
        <GenericMessageSlideOut
          allSelected={allSelected}
          query={query}
          title='Reschedule request'
          study={study}
          participations={participations}
          cta='Send request'
          event='reschedule_request'
          onClose={onClose}
          onSuccess={handleSuccess}
        />
      )}
      {mode === UPDATE_MODERATOR && (
        <UpdateModeratorModal
          study={study}
          allSelected={allSelected}
          query={query}
          participationIds={participations.map((p) => p.id)}
          onClose={onClose}
          onSuccess={handleSuccess}
        />
      )}
      {mode === MANUAL_SCHEDULE_SLIDEOUT && (
        <ManualScheduleSlideOut
          allSelected={allSelected}
          query={query}
          study={study}
          parties={participations}
          onClose={onClose}
          onSuccess={handleSuccess}
        />
      )}
      {mode === MANUAL_RESCHEDULE_MODAL && (
        <ManualRescheduleModal
          study={study}
          participation={participations[0]}
          onClose={onClose}
          onSuccess={handleSuccess}
        />
      )}
      {mode === CANCEL_INTERVIEW_SLIDEOUT && (
        <CancelSlideOut
          allSelected={allSelected}
          query={query}
          study={study}
          participations={participations}
          ids={participations.map((p) => p.customer_id)}
          onClose={onClose}
          onSuccess={handleSuccess}
        />
      )}
      {mode === CANCEL_TASK_SLIDEOUT && (
        <CancelTaskSlideOut
          allSelected={allSelected}
          query={query}
          study={study}
          parties={participations}
          onClose={onClose}
          onSuccess={handleSuccess}
        />
      )}
    </>
  );
};
