import * as React from 'react';
import { Text } from '@components/common';
import { useGetStudyCalendarQuery } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/api';
import { SUFFIXES } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/constants';
import { getInitialTimeSlot } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/AdditionalOptionsPanel/utils';
import pluralize from 'pluralize';
import { TimezoneDesc } from '../../../shared/TimezonePicker';

export const PreviewAvailabilityHelper: React.FC<{ study: Study; selectedTimezone: TimezoneDesc }> = ({
  study,
  selectedTimezone
}) => {
  const { data: calendar } = useGetStudyCalendarQuery({ studyId: study.id });
  const minSchedNoticeSuffix = SUFFIXES.filter(
    (s) => ((study.minimum_booking_notice_in_minutes || 0) / s.multiplier) % 1 === 0
  )[0];
  const timeSlot = getInitialTimeSlot(study.time_slot_settings, selectedTimezone.toString());

  return (
    <div className='border-custom-brand-secondary pt-4 mt-4 text-center border-t'>
      <Text mb='4' bold>
        Don't see a timeslot that you think should be available? (Participants won't see this)
      </Text>
      <Text>Please review the possible reasons:</Text>

      <div className='w-full flex justify-center text-left'>
        <ul className='list-disc mt-4'>
          <li>
            <Text h='400'>Existing moderator conflicting events</Text>
          </li>
          <li>
            <Text h='400'>Scheduling dates and hours</Text>
          </li>
          {calendar?.booking_limit_daily && calendar?.booking_limit_daily > 0 ? (
            <li>
              <Text h='400'>{calendar.booking_limit_daily} daily booking limit</Text>
            </li>
          ) : null}
          {calendar?.booking_limit_weekly && calendar?.booking_limit_weekly > 0 ? (
            <li>
              <Text h='400'>{calendar.booking_limit_weekly} weekly booking limit</Text>
            </li>
          ) : null}
          {timeSlot.call_buffer ? (
            <li>
              <Text h='400'>{pluralize('minute', timeSlot.call_buffer, true)} buffer between events</Text>
            </li>
          ) : null}
          {study.minimum_booking_notice_in_minutes ? (
            <li>
              <Text h='400'>
                {pluralize(
                  minSchedNoticeSuffix.label,
                  study.minimum_booking_notice_in_minutes / minSchedNoticeSuffix.multiplier,
                  true
                )}{' '}
                minimum scheduling notice
              </Text>
            </li>
          ) : null}
          {calendar?.maximum_scheduling_notice ? (
            <li>
              <Text h='400'>
                {pluralize('day', calendar?.maximum_scheduling_notice, true)} maximum scheduling notice
              </Text>
            </li>
          ) : null}
        </ul>
      </div>

      <Text mt='4' light h='400'>
        Note: we're actively working on ways to help you understand the specific reason you can't see a timeslot so you
        can debug these issues yourself
      </Text>
    </div>
  );
};
