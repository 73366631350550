import { api } from '@api/reduxApi';
import { ServerFilterQuery } from '@components/CandidatesApp/CandidatesIndex/types';

const studyBackgroundTasksApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStudyBackgroundTasks: builder.query<BackgroundTask[], { objectId: number }>({
      query: ({ objectId }) => `/studies/${objectId}/background_tasks`
    })
  })
});

const markInvitedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    markInvited: builder.mutation<BackgroundTask, { studyId: number; ids?: number[]; query?: ServerFilterQuery }>({
      query: ({ studyId, ids, query }) => ({
        url: `/studies/${studyId}/participations/bulk/mark_invited`,
        method: 'POST',
        body: { ids, query }
      })
    })
  })
});

const bookApi = api.injectEndpoints({
  endpoints: (builder) => ({
    book: builder.mutation<BackgroundTask, { studyId: number; ids: number[]; query?: ServerFilterQuery }>({
      query: ({ studyId, ids, query }) => ({
        url: `/studies/${studyId}/participations/bulk/book`,
        method: 'POST',
        body: { ids, query }
      })
    })
  })
});

const removeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    remove: builder.mutation<BackgroundTask, { studyId: number; ids: number[]; query?: ServerFilterQuery }>({
      query: ({ studyId, ids, query }) => ({
        url: `/studies/${studyId}/participations/bulk/remove`,
        method: 'POST',
        body: { ids, query }
      })
    })
  })
});

const noShowApi = api.injectEndpoints({
  endpoints: (builder) => ({
    noShow: builder.mutation<BackgroundTask, { studyId: number; ids: number[]; query?: ServerFilterQuery }>({
      query: ({ studyId, ids, query }) => ({
        url: `/studies/${studyId}/participations/bulk/no_show`,
        method: 'POST',
        body: { ids, query }
      })
    })
  })
});

const cancelApi = api.injectEndpoints({
  endpoints: (builder) => ({
    cancel: builder.mutation<BackgroundTask, { studyId: number; ids?: number[]; query?: ServerFilterQuery }>({
      query: ({ studyId, ids, query }) => ({
        url: `/studies/${studyId}/participations/bulk/cancel`,
        method: 'POST',
        body: { ids, query }
      })
    })
  })
});

const startApi = api.injectEndpoints({
  endpoints: (builder) => ({
    start: builder.mutation<BackgroundTask, { studyId: number; ids: number[]; query?: ServerFilterQuery }>({
      query: ({ studyId, ids, query }) => ({
        url: `/studies/${studyId}/participations/bulk/start`,
        method: 'POST',
        body: { ids, query }
      })
    })
  })
});

const shortlistApi = api.injectEndpoints({
  endpoints: (builder) => ({
    shortlist: builder.mutation<BackgroundTask, { studyId: number; ids?: number[]; query?: ServerFilterQuery }>({
      query: ({ studyId, ids, query }) => ({
        url: `/studies/${studyId}/participations/bulk/shortlist`,
        method: 'POST',
        body: { ids, query }
      })
    })
  })
});

const consentFormExportApi = api.injectEndpoints({
  endpoints: (builder) => ({
    consentFormExport: builder.mutation<
      BackgroundTask,
      {
        studyId: number;
        ids?: number[];
        query?: ServerFilterQuery;
      }
    >({
      query: ({ studyId, ids, query }) => ({
        url: `/studies/${studyId}/participations/bulk/consent_form_export`,
        method: 'POST',
        body: { ids, query }
      })
    })
  })
});

export const { useGetStudyBackgroundTasksQuery } = studyBackgroundTasksApi;
export const { useMarkInvitedMutation } = markInvitedApi;
export const { useBookMutation } = bookApi;
export const { useRemoveMutation } = removeApi;
export const { useNoShowMutation } = noShowApi;
export const { useCancelMutation } = cancelApi;
export const { useStartMutation } = startApi;
export const { useShortlistMutation } = shortlistApi;
export const { useConsentFormExportMutation } = consentFormExportApi;
