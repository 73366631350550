import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { api } from '@api/reduxApi';
import { Card, InputWithAddons, Loading, Select, Tooltip } from '@components/common';
import { RestrictedAction } from '@components/shared/RestrictedAction';
import { useToaster } from '@components/stores/toaster';
import { getInitialTimeSlot } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/AdditionalOptionsPanel/utils';
import { buildInitialCalAvailableSlots } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/utils';
import { SingleUserSVG } from '@components/svgs';
import { ChangeFn, getChangeFn } from '@components/utils';
import { ChangeIncentiveModal, useStudyFunder } from '@components/Wallet';
import { useDeviceType } from '@hooks/useDeviceType';
import { useDisabledFeatures } from '@hooks/useDisabledFeatures';
import { useFeature } from '@hooks/useFeature';
import { usePermission } from '@hooks/usePermission';
import { useUser } from '@hooks/useUser';

import { useStudyPublishValidator } from '../../hooks/useStudyPublishValidator';
import { failedUpdate } from '../../toasts';
import { StepHelper, StepTitle } from '../shared';
import {
  ExternalCandidatesOptions,
  IncentivesOptions,
  ResearchGoal,
  ScreenerOptions,
  StudyLimitFields,
  StudyTitle
} from './components';
import { AdditionalOptions } from './components/AdditionalOptions/AdditionalOptions';
import { ConsentOptions } from './components/ConsentOptions';
import {
  AddIncentiveModal,
  InvalidIncentiveModal,
  RemoveIncentiveModal
} from './components/IncventivesOptions/components';
import { FormGroup, Helper, Label } from './components/IncventivesOptions/components/IncentivesForm';
import { CONTINUOUS_OPTIONS, TASK_DURATION_LABEL, TITLES } from './constants';
import { Alerts } from '@components/StudiesApp/components/StudyPublished/components/Alerts';

// creatable styles
export type Styles = 'panel' | 'video_call' | 'survey' | 'online_task' | 'unmoderated_test';

interface Props {
  options: PlanOption[];
  setOptions: (value: PlanOption[], save?: (study: Partial<Study>) => void) => void;
  participations?: Participation[];
  study: Study;
  onSave: (study: Partial<Study>) => void;
}

export const getLimitInputName = (study: Study) => {
  if (study.focus_group) {
    return 'session_capacity';
  } else if (study.continuous) {
    return 'recurring_participant_limit';
  }
  return 'participant_limit';
};

export const Plan: React.FC<Props> = ({ options, setOptions, participations, study, onSave }) => {
  const { isMobile } = useDeviceType();

  const showToast = useToaster();

  const user = useUser();

  const canUpdateStudy = usePermission<Study>('updateStudy')(study);

  const [continuous, setContinuous] = useState<Study['continuous']>(study.continuous);

  const [previewMaximumSlots, { isLoading, isSuccess, isError, data: preview }] = api.usePreviewMaximumSlotsMutation();
  const { data: studySlotsBalance } = api.useGetStudySlotsBalanceQuery(study.id);

  const [incentiveModal, setIncentiveModal] = useState<'add' | 'remove' | 'change' | 'unfund' | 'invalid' | null>();
  const [taskDuration, setTaskDuration] = useState(
    study.duration_in_minutes ? study.duration_in_minutes.toString() : ''
  );

  const hasSegments = useFeature('party_segments');
  const showParticipantSegments = !study?.focus_group && !study?.continuous && hasSegments;

  const { items } = useStudyPublishValidator({ study });

  const change = getChangeFn<Partial<Study>>({ id: study.id }, onSave) as ChangeFn;

  const debounced = useDebouncedCallback(change, process.env.NODE_ENV === 'test' ? 5 : 300);

  const debouncedChange: ChangeFn = debounced.callback;

  const validator = items.find((item) => item.id === 'plan');
  const validatorError = validator?.failedKeys?.includes('participation_limit');

  const disabledFeatures = useDisabledFeatures();

  useEffect(() => {
    if (isError) {
      showToast(failedUpdate());
    }
  }, [isError]);

  useEffect(() => {
    if (study.state !== 'draft' || study.style !== 'video_call') return;

    let calAvailableSlots: CalenderAvailableSlots;
    const slots = buildInitialCalAvailableSlots(study);

    // this logic is to handle the case when the user changes the study type from continuous to one-off or vice versa
    // we should build new slots only if no slots
    // we should only change the end_date if the study type is changed
    if (!study.cal_available_slots) {
      calAvailableSlots = slots;
    } else {
      const studyTypeIsChanged =
        (study.continuous && study.cal_available_slots?.end_date) ||
        (!study.continuous && !study.cal_available_slots?.end_date);

      calAvailableSlots = studyTypeIsChanged
        ? { ...study.cal_available_slots, end_date: slots.end_date }
        : study.cal_available_slots;
    }

    const timeSlot = getInitialTimeSlot(study.time_slot_settings, user.time_zone) as OneOffTimeSlot;

    const defaults: Partial<Study> = {};

    if (!study.time_slot_settings?.timezone) {
      defaults.time_slot_settings = timeSlot;
    }

    defaults.cal_available_slots = calAvailableSlots;

    onSave({ id: study.id, ...defaults });
  }, [study.continuous]);

  const peopleSuffix = () => {
    if (study.focus_group) {
      return 'people per session';
    }

    return 'people';
  };

  const [participantLimit, setParticipantLimit] = useState(study[getLimitInputName(study)]);
  const handleBlurParticipantLimit: React.FocusEventHandler<HTMLInputElement> = (e) => {
    if (!e.currentTarget.value || e.currentTarget.value === study[getLimitInputName(study)]?.toString()) {
      return;
    }

    let value = parseInt(e.currentTarget.value, 10);
    if (!isNaN(value)) {
      value = Math.min(9999, value);
    }

    if (study.state === 'draft') {
      change?.(getLimitInputName(study), value);
      return;
    }
    if (!study[getLimitInputName(study)] || study[getLimitInputName(study)] === value) {
      onSave({ id: study.id, [getLimitInputName(study)]: value });
      return;
    }
    if (study.incentive_method === 'tremendous' && !incentiveModal) {
      previewMaximumSlots({ ...study, [getLimitInputName(study)]: value });
      setIncentiveModal('change');
    } else {
      change(getLimitInputName(study), value);
    }
  };

  const canUpdate = !['closed', 'archived'].includes(study.state) && !study.deleted_at && canUpdateStudy;
  const canChangeIncentive =
    canUpdate &&
    (['draft', 'pending'].includes(study.state) ||
      (study.state === 'active' &&
        study.currently_active_external_candidates_requests_count === 0 &&
        !(participations || []).some(({ status }) =>
          ['completed', 'requested', 'booked', 'started'].includes(status)
        )));

  const funder = useStudyFunder({ study });

  const handleSave = async () => {
    if (study.state === 'draft') return;
    const wallet = await funder.prepare({
      participant_limit: study.maximum_slots,
      incentive: study.incentive,
      incentive_method: study.incentive_method
    });

    if (wallet) {
      setIncentiveModal('change');
    } else {
      onSave(study);
    }
  };

  const onParticipantsLimitChange = (v: number) => {
    setParticipantLimit(v);

    // update participation limit on change if we don't need to open fund modal
    if (study.state === 'draft' || study.incentive_method !== 'tremendous') {
      debouncedChange(getLimitInputName(study), v);
    }
  };

  const limitTitle = () => {
    if (study.focus_group) {
      return 'How many participants do you want in each focus group?';
    }

    return 'Participation limit';
  };

  const limitSubtitle = () => {
    if (study.focus_group) {
      return "We'll close the calendar for that session after the maximum is reached.";
    } else if (study.style === 'survey') {
      return 'This sets the maximum number of responses for your survey.';
    }

    return 'This controls how many people will be able to schedule calls for the study.';
  };

  const externalCandidatesToggleDisabled = !canUpdateStudy || study.currency !== 'USD';

  return (
    <>
      <Alerts
        className='desktop:px-8 px-4 mt-6'
        slots={studySlotsBalance}
        user={user}
        study={study}
        openFundModal={() => {
          previewMaximumSlots(study);
          setIncentiveModal('change');
        }}
        canAction={canUpdate}
        keys={['must_fund']}
      />
      <div className='pt-gutter'>
        <div className='text-center'>
          <StepTitle>{study.focus_group ? "Let's plan your focus groups" : TITLES[study.style]}</StepTitle>
          <StepHelper>Set up the basics for your study. You can change this later if needed.</StepHelper>
        </div>
        <Card className='max-w-2xl px-6 py-0 mx-auto'>
          <StudyTitle study={study} updateStudy={onSave} />
          <ResearchGoal onSave={onSave} study={study} disabled={!canUpdateStudy} />
          <div className='border-b border-gray-200'>
            <div className='py-6'>
              <Label htmlFor='Participant limit'>{limitTitle()}</Label>

              <span id='Participant limit desc' className='h400 block mb-1 text-gray-500'>
                {limitSubtitle()}
              </span>

              <div className='tablet:flex-row tablet:space-y-0 tablet:space-x-4 flex flex-col w-full space-y-3'>
                <div className='flex-1'>
                  <InputWithAddons
                    id='Participant limit'
                    type='number'
                    disabled={!canUpdateStudy}
                    name={getLimitInputName(study)}
                    ariaLabel='Participant limit'
                    className='no_arrows h-10'
                    value={participantLimit?.toString() || ''}
                    onChange={onParticipantsLimitChange}
                    prefix=''
                    icon={<SingleUserSVG />}
                    placeholder='Enter number…'
                    suffix={peopleSuffix()}
                    onBlur={handleBlurParticipantLimit}
                    pr='16'
                    error={validatorError}
                    onEnter={(e) => e.currentTarget.blur()}
                    min={1}
                  />
                </div>
                {study.style === 'video_call' && !study.focus_group ? (
                  <div className='flex-1'>
                    <RestrictedAction feature='continuous' autoShow={false}>
                      {({ can, may, show }) => (
                        <Select
                          wrapperClass='mt-1 xx-limit-type'
                          overflowClass='visible'
                          options={CONTINUOUS_OPTIONS}
                          onChange={(v) => {
                            if (!may || !can) {
                              show?.();
                              return;
                            }
                            setContinuous(v === 'weekly');
                            change?.('continuous', (v === 'weekly') as Study['continuous']);
                          }}
                          disabled={study.state !== 'draft' || !canUpdateStudy}
                          value={continuous ? 'weekly' : 'total'}
                        />
                      )}
                    </RestrictedAction>
                  </div>
                ) : (
                  <div className={classNames('flex-1', { hidden: isMobile })} />
                )}
              </div>
              {showParticipantSegments && (
                <div className='pt-6'>
                  <div className='flex items-center space-x-2'>
                    <div className='h500-bold block'>Participant segments</div>
                    <Tooltip content='This information is used to inform you how well potential participants match your ideal profile, to make it easier to decide who to invite.  Defining this criteria is optional and you can change it at any time.' />
                  </div>
                  <span className='h400 block mb-1 mb-2 text-gray-500'>
                    Define the attributes of your ideal participants to easily evaluate potential matches.
                  </span>
                  <StudyLimitFields studyId={study.id} readOnly={!canUpdateStudy} />
                </div>
              )}
            </div>
            {['survey', 'online_task', 'unmoderated_test'].includes(study.style) && (
              <FormGroup>
                <Label>{TASK_DURATION_LABEL[study.style]}</Label>
                <Helper>This gives participants an estimate of what to expect.</Helper>
                <div className='flex'>
                  <div className='flex-1'>
                    <InputWithAddons
                      disabled={!canUpdateStudy}
                      name='duration'
                      ariaLabel='Duration'
                      className='no_arrows'
                      value={taskDuration}
                      onChange={(v: string) => {
                        const value = Number(v.replace(/[^0-9]/g, '')).toString();
                        setTaskDuration(value);
                        debouncedChange('duration_in_minutes', value);
                      }}
                      prefix=''
                      placeholder='Enter number…'
                      suffix='minutes'
                    />
                  </div>
                  <div className={classNames('flex-1', { hidden: isMobile })} />
                </div>
              </FormGroup>
            )}
          </div>
          {study.style !== 'panel' && (
            <>
              {!disabledFeatures.external_recruitment && (
                <ExternalCandidatesOptions
                  disabled={externalCandidatesToggleDisabled}
                  study={study}
                  options={options}
                  setOptions={setOptions}
                />
              )}
              <IncentivesOptions
                handleSave={handleSave}
                setModal={setIncentiveModal}
                canChangeIncentive={canChangeIncentive}
                study={study}
                change={change}
                options={options}
                setOptions={setOptions}
              />
            </>
          )}
          <ScreenerOptions
            disabled={!canUpdateStudy}
            study={study}
            options={options}
            setOptions={setOptions}
            change={change}
          />
          {canUpdateStudy && (
            <>
              <ConsentOptions study={study} onSave={onSave} />
              <AdditionalOptions change={change} study={study} />
            </>
          )}
        </Card>
        {incentiveModal === 'change' && isLoading && <Loading />}
        {incentiveModal === 'change' && !isLoading && isSuccess && (
          <ChangeIncentiveModal
            study={study}
            funder={funder}
            limitCount={preview?.maximum_slots || 0}
            onCancel={() => {
              // TODO:test this with continuous/focus groups
              setParticipantLimit(study[getLimitInputName(study)]);
              setIncentiveModal(null);
            }}
            onConfirm={() => {
              change(getLimitInputName(study), participantLimit);
              setIncentiveModal(null);
            }}
          />
        )}
        {incentiveModal === 'invalid' && <InvalidIncentiveModal onClose={() => setIncentiveModal(null)} />}
        {incentiveModal === 'add' && (
          <AddIncentiveModal
            study={study}
            limitCount={study.maximum_slots}
            funder={funder}
            onClose={() => setIncentiveModal(null)}
            onSave={onSave}
          />
        )}
        {incentiveModal === 'unfund' && (
          <ChangeIncentiveModal
            funder={funder}
            limitCount={study.maximum_slots}
            study={{ ...study, incentive_method: '' }}
            onCancel={() => setIncentiveModal(null)}
            onConfirm={() => {
              change('incentive_method', '');
              setIncentiveModal(null);
            }}
          />
        )}

        {incentiveModal === 'remove' && (
          <RemoveIncentiveModal
            study={study}
            onClose={() => setIncentiveModal(null)}
            onConfirm={() => {
              const newOptions = options.filter((v) => v !== 'incentive');
              setOptions(newOptions);
              // Actualy save it?
              setIncentiveModal(null);
            }}
          />
        )}
      </div>
    </>
  );
};
