// libs
import cn from 'classnames';
import { Preview } from 'components/Loom';
import { CancelEditModal } from 'components/shared/CancelEditModal/CancelEditModal';
import { useOnKeypress } from 'components/utils';
import { useProfileContext } from 'hooks/useProfileContext';
// hooks
import * as React from 'react';
import { useState } from 'react';
import { useFeature } from '@hooks/useFeature';

// components
import { Button, Dropdown, DropdownLink, SlideOut } from '@components/common';
import { UserProfileSVG } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/NylasEventPopup/components/eventPopupSvgs';
import { ChevronDownSVG, SingleUserSVG, VerticalDotsSVG } from '@components/svgs';

import {
  ActivitySection,
  Assets,
  CandidateStudies,
  Messages,
  ConsentForms,
  InterviewDetails,
  Interviews,
  ProfileAttributes,
  ScreenerResponses
} from './components';

const Section: React.FC<{ className?: string; borderTop?: boolean }> = ({ borderTop, className, children }) => (
  <div className={cn(className, 'p-6', { 'border-t border-gray-200': borderTop })}>{children}</div>
);

const SectionHeader: React.FC = ({ children }) => (
  <span className='block mb-6 text-2xl font-bold text-gray-700'>{children}</span>
);

export type Actions = {
  markAs?: {
    onClick: (status: string) => void;
  };
  invite?: (id: number) => void;
  sendScreener?: (id: number) => void;
  shortlist?: (id: number) => void;
  inviteToPanel?: (id: number) => void;
};

export type ProfileSlideoutProps = {
  header?: React.ReactNode;
  open: boolean;
  switchCandidate?: (shift: number) => void;
  onClose: () => void;
  candidate: Candidate;
  onChangeCandidate?: (candidate: Candidate) => void;

  canUpdate: boolean;
  actions?: Actions;
  renderActions?: () => React.ReactNode;
  activeParticipation?: Participation;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;

  showSections: {
    about?: boolean;
    messages?: { participation: Participation };
    consent?: { participation: Participation };
    interviewDetails?: { participation: Participation };
    loomRecordingId?: string;
    preScreenerResponses?: { studyId: number; participationId: number; screenerId: number; title?: string } | null;
    surveyScreenerResponses?: { studyId: number; participationId: number; screenerId: number; title?: string } | null;
    generalSections?: boolean;
  };
  createAttr?: (attr: Partial<Attr_>) => Promise<Attr_ | undefined>;
};

interface FooterProps {
  canUpdate: boolean;
  actions: Actions;
  closeMarkAs: () => void;
  candidate: Candidate;
  onClose: () => void;
  renderActions?: () => React.ReactNode;
  markAsDropdownOpen: boolean;
  toggleMarkAs: () => void;
  switchCandidate?: (shift: number) => void;
  activeParticipation?: Participation;
}

const Footer: React.FC<FooterProps> = ({
  canUpdate,
  switchCandidate,
  actions,
  closeMarkAs,
  candidate,
  onClose,
  renderActions,
  markAsDropdownOpen,
  toggleMarkAs
}) => {
  const [showMenu, setShowMenu] = useState<'compose' | 'three_dots' | null>(null);

  useOnKeypress('ArrowLeft', { skipInput: true }, () => switchCandidate?.(-1));

  useOnKeypress('ArrowRight', { skipInput: true }, () => switchCandidate?.(1));

  const markAsComplete = () => {
    actions.markAs?.onClick('complete');
    closeMarkAs();
  };

  const markNoShow = () => {
    actions.markAs?.onClick('no_show');
    closeMarkAs();
  };

  const inviteToStudy = () => {
    onClose();
    actions.invite?.(candidate.id);
  };

  const sendScreener = () => {
    onClose();
    actions.sendScreener?.(candidate.id);
  };

  const shortlist = () => {
    onClose();
    actions.shortlist?.(candidate.id);
  };

  const inviteToPanel = () => {
    onClose();
    actions.inviteToPanel?.(candidate.id);
  };

  return (
    <div className='flex items-end space-x-6'>
      {actions.markAs && canUpdate && (
        <Dropdown
          primary
          aboveButton
          text='Mark as…'
          isOpen={markAsDropdownOpen}
          onClick={toggleMarkAs}
          onClose={closeMarkAs}
          position='left'
        >
          <DropdownLink onClick={markAsComplete}>Mark as completed</DropdownLink>
          <DropdownLink onClick={markNoShow}>Mark as no-show</DropdownLink>
        </Dropdown>
      )}
      {(actions.invite || actions.sendScreener || actions.inviteToPanel) && (
        <Dropdown
          primary
          text='Compose'
          icon='composeEmail'
          aboveButton
          isOpen={showMenu === 'compose'}
          onClick={() => setShowMenu('compose')}
          onClose={() => setShowMenu(null)}
        >
          {actions.invite && (
            <DropdownLink className='border-b' onClick={inviteToStudy}>
              Participation invite
            </DropdownLink>
          )}
          {actions.sendScreener && (
            <DropdownLink className='border-b' onClick={sendScreener}>
              Screener invite
            </DropdownLink>
          )}

          {actions.inviteToPanel && (
            <DropdownLink className='xx-bulk-panel border-b' onClick={inviteToPanel}>
              Panel opt-in form
            </DropdownLink>
          )}
          <DropdownLink disabled>Emails won't be sent yet</DropdownLink>
        </Dropdown>
      )}
      {actions.shortlist && <Button onClick={shortlist}>Shortlist</Button>}

      {renderActions ? (
        renderActions()
      ) : (
        <Dropdown
          aboveButton
          className='xx-three-dots-dropdown'
          dataTestId='three-dots-dropdown'
          onClick={() => setShowMenu('three_dots')}
          isOpen={showMenu === 'three_dots'}
          onClose={() => setShowMenu(null)}
          text={<VerticalDotsSVG />}
          hideCaret
        >
          <DropdownLink className='flex items-center' spa href={`/candidates/${candidate.id}`}>
            <SingleUserSVG className='mr-2' />
            <div>View on full page</div>
          </DropdownLink>
        </Dropdown>
      )}

      {switchCandidate && (
        <>
          <Button className='h-10' onClick={() => switchCandidate(-1)}>
            <ChevronDownSVG className='transform rotate-90' />
          </Button>
          <Button className='h-10' onClick={() => switchCandidate(1)}>
            <ChevronDownSVG className='transform -rotate-90' />
          </Button>
        </>
      )}
    </div>
  );
};

export const ProfileSlideout: React.FC<ProfileSlideoutProps> = ({
  canUpdate,
  header,
  open,
  onClose,
  candidate,
  onChangeCandidate,
  actions = {},
  renderActions,
  showSections,
  setLoading,
  createAttr,
  switchCandidate,
  activeParticipation
}) => {
  const [markAsDropdownOpen, setMarkAsDropdownOpen] = useState(false);

  const { isDirty, setIsDirty, cancelModal, setCancelModal } = useProfileContext();

  const closeMarkAs = () => setMarkAsDropdownOpen(false);
  const toggleMarkAs = () => setMarkAsDropdownOpen(!markAsDropdownOpen);

  const handleClose = () => {
    if ((Object.values(isDirty) || []).includes(true)) {
      setCancelModal(true);
    } else {
      onClose();
    }
  };

  if (!open) {
    return null;
  }

  return (
    <>
      <SlideOut
        noHeader
        onClose={handleClose}
        size='2xl'
        renderFooter={() => (
          <Footer
            canUpdate={canUpdate}
            switchCandidate={switchCandidate}
            actions={actions}
            closeMarkAs={closeMarkAs}
            candidate={candidate}
            onClose={handleClose}
            renderActions={renderActions}
            markAsDropdownOpen={markAsDropdownOpen}
            toggleMarkAs={toggleMarkAs}
            activeParticipation={activeParticipation}
          />
        )}
      >
        <div className='p-6'>
          {header && <div className='pb-4'>{header}</div>}
          <h2 className='mb-2'>{candidate.name}</h2>
        </div>

        {showSections.interviewDetails && (
          <Section className='-mb-3' borderTop>
            <SectionHeader>Interview details</SectionHeader>
            <InterviewDetails participation={showSections.interviewDetails.participation} />
          </Section>
        )}

        {showSections.about && (
          <Section borderTop={!showSections.interviewDetails}>
            <SectionHeader>About</SectionHeader>
            <ProfileAttributes
              createAttr={createAttr}
              setLoading={setLoading}
              candidate={candidate}
              onUpdate={onChangeCandidate}
            />
          </Section>
        )}
        {showSections.messages && (
          <Section borderTop>
            <SectionHeader>Messages</SectionHeader>
            <Messages participation={showSections.messages.participation} />
          </Section>
        )}
        {showSections.consent && (
          <Section borderTop>
            <SectionHeader>Consent</SectionHeader>
            <ConsentForms participation={showSections.consent.participation} />
          </Section>
        )}
        {showSections.loomRecordingId && (
          <Section borderTop>
            <SectionHeader>Recording</SectionHeader>
            <Preview id={showSections.loomRecordingId} />
          </Section>
        )}
        {showSections.preScreenerResponses && (
          <Section borderTop>
            <SectionHeader>{showSections.preScreenerResponses.title || 'Screener'}</SectionHeader>
            <ScreenerResponses
              studyId={showSections.preScreenerResponses.studyId}
              screenerId={showSections.preScreenerResponses.screenerId}
              participationId={showSections.preScreenerResponses.participationId}
            />
          </Section>
        )}
        {showSections.surveyScreenerResponses && (
          <Section borderTop>
            <SectionHeader>{showSections.surveyScreenerResponses.title || 'Survey'}</SectionHeader>
            <ScreenerResponses
              studyId={showSections.surveyScreenerResponses.studyId}
              screenerId={showSections.surveyScreenerResponses.screenerId}
              participationId={showSections.surveyScreenerResponses.participationId}
            />
          </Section>
        )}

        {showSections.generalSections && (
          <>
            <Section borderTop>
              <SectionHeader>Studies</SectionHeader>
              <CandidateStudies candidateId={candidate.id} />
            </Section>
            <Section borderTop>
              <SectionHeader>Interviews</SectionHeader>
              <Interviews candidateId={candidate.id} />
            </Section>
            <Section borderTop>
              <SectionHeader>Assets</SectionHeader>
              <Assets candidateId={candidate.id} />
            </Section>
            <Section borderTop>
              <SectionHeader>Activity</SectionHeader>
              <ActivitySection candidateId={candidate.id} />
            </Section>
          </>
        )}
      </SlideOut>

      <CancelEditModal
        discardMessage="Some attributes weren't updated."
        setEdit={onClose}
        setIsDirty={setIsDirty}
        onClose={() => setCancelModal(false)}
        cancelModal={cancelModal}
      />
    </>
  );
};
