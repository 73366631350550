import pluralize from 'pluralize';
import React, { useEffect, useMemo, useState } from 'react';

import { api } from '@api/reduxApi';
import { Alert, AlertLink, Button, LayoutToggle, Spinner, Tabs, Text, Toggle } from '@components/common';
import { Grid } from '@components/common/Grid';
import { DashboardLayout, DashboardLayoutBody } from '@components/layouts/DashboardLayout';
import { PageHeader } from '@components/shared/PageHeader';
import { RestrictedButton } from '@components/shared/RestrictedButton';
import { SortDropdown } from '@components/shared/SortDropdown';
import { AnyAllToggle, SegmentActions, TableFilters, useTableFilters } from '@components/shared/TableFilters';
import { ZDSPage } from '@components/shared/ZDS/components/ZDSPage';
import { parse } from '@components/shared/TableFilters/utils/encode';
import { Paginator } from '@components/shared/Paginator';
import { useCollectionView } from '@components/stores/view';
import { track } from '@components/tracking';
import { useAccount } from '@hooks/useAccount';
import { usePlan } from '@hooks/usePlan';
import { useUser } from '@hooks/useUser';

import { NewStudyAction } from '../StudyNew';
import { ListViewTable } from './components/ListViewTable';
import { NoResults } from './components/NoResults';
import { RowViewTable } from './components/RowViewTable';
import { StudyCard } from './components/StudyCard';
import { buildStudiesAttributes } from './filters/buildStudiesAttributes';
import { Skeleton } from './Skeleton';

const TAB_LABELS = {
  my: 'My studies',
  all: 'All studies'
};

interface Props {
  team?: Team | null;
}

export const StudiesListPage: React.FC<Props> = ({ team }) => {
  const user = useUser();

  const {
    account: { status, team: accountMembers }
  } = useAccount();

  const { limits, hasQuota } = usePlan();

  const viewHook = useCollectionView();
  const {
    view: { sort, layout, groupBy, tab, toggle },
    setView
  } = viewHook;

  function setLayout(layout) {
    setView({ layout });
  }

  function setSort(sort) {
    setView({ sort });
  }

  function setGroupBy(groupBy) {
    setView({ groupBy });
  }

  function setToggle(toggle) {
    setView({ toggle });
  }

  const [value, setValue] = useState<string>('');

  const { data: studyAttrs, isSuccess, isError } = api.useGetStudyAttrsQuery();

  const attrs = useMemo(
    () => buildStudiesAttributes({ onlyBackendFilters: true, team: accountMembers, studyAttrs: studyAttrs || [] }),
    [studyAttrs, accountMembers]
  );

  const filtersHook = useTableFilters<Study>({
    definitions: attrs.filters,
    trackKey: 'studies',
    syncWithURL: isSuccess || isError
  });

  const [page, setPage] = useState(0);
  const { data: totalCount, isLoading: isLoadingCount } = api.useGetStudiesCountQuery();
  const { data, isFetching, isLoading } = api.useGetStudiesPagedQuery({
    q: value,
    page: page + 1,
    teamId: team?.id,
    tab: tab || 'all',
    filters: filtersHook.filters.map((filter) => parse(filter)),
    sort: sort.value,
    sortDesc: sort.desc,
    includeArchived: toggle
  });
  const studies = data?.data || [];
  const currentPage = data?.meta?.page || 0;
  const totalPages = data?.meta?.pages || 0;
  const canNextPage = page < totalPages - 1;
  const canPreviousPage = page > 0;

  const nextPage = () => setPage((page) => page + 1);
  const previousPage = () => setPage((page) => page - 1);

  const onSortChange = ({ value }) => {
    setSort({ value, desc: false });
  };

  const onSearchChange = (value: string) => {
    setValue(value);
  };

  const renderStudies = useMemo(() => {
    if (studies.length === 0) {
      return <NoResults />;
    }

    if (layout === 'grid') {
      return (
        <Grid gap={6} monitor={4} desktop={3} tablet={2} mobile={1}>
          {studies?.map((s) => s && <StudyCard key={s.id} study={s} />)}
        </Grid>
      );
    }

    if (layout === 'row') {
      return <RowViewTable records={studies} />;
    }

    return <ListViewTable records={studies} onlyBackendFilters />;
  }, [studies, layout]);

  const renderFilters = () => (
    <div className='px-page flex items-center border-t border-gray-200'>
      <div className='flex-1'>
        <TableFilters<Study> hook={filtersHook} defaultShowInput={!filtersHook.filters.length} />
      </div>
      <div className='flex py-3 pl-3 space-x-3 border-l border-gray-200'>
        <AnyAllToggle value={filtersHook.op} onChange={filtersHook.setOp} />
        <SegmentActions onClear={filtersHook.clearFilters} anyFilters={filtersHook.filters.length > 0} />
      </div>
    </div>
  );

  if (isLoadingCount || isLoading) {
    return <Skeleton />;
  }

  if (totalCount === 0) {
    return <ZDSPage types={['studies']} teamId={team?.id} />;
  }

  return (
    <DashboardLayout>
      <PageHeader
        h1='Studies'
        renderSortBy={() => (
          <SortDropdown wrapperClass='w-full' options={attrs.sortOptions} value={sort.value} onChange={onSortChange} />
        )}
        renderLayout={() => <LayoutToggle withRow value={layout} onChange={setLayout} />}
        searchProps={{
          placeholder: 'Search…',
          addDebounce: true,
          onSearch: onSearchChange,
          value
        }}
        renderTabs={() => (
          <Tabs
            current={tab || 'all'}
            labels={TAB_LABELS}
            onSelect={(tab) => {
              track('changed_study_owner_tab', { tab });
              setView({ tab });
              setPage(0);
            }}
            className='px-page flex items-center border-t border-gray-200'
            tabs={['my', 'all']}
          />
        )}
        renderFilters={renderFilters}
        filtersApplied={!!filtersHook.filters.length}
        renderCta={() => (
          <NewStudyAction>
            {({ openSlideout }) => (
              <RestrictedButton
                limit='studies'
                permission='createStudy'
                action='New study'
                className='xx-new-study-cta'
              >
                <Button onClick={() => openSlideout({ teamId: team?.id })} primary medium>
                  New study
                </Button>
              </RestrictedButton>
            )}
          </NewStudyAction>
        )}
        renderToggle={() => (
          <div className='flex items-center justify-between'>
            <div className='text-sm'>Show archived studies</div>
            <Toggle name='Show archived studies' onToggle={setToggle} on={toggle} />
          </div>
        )}
        team={team}
      />

      <DashboardLayoutBody>
        {!hasQuota('studies') && ['active', 'trial'].includes(status) && (
          <Alert className='mb-4' type='warning'>
            <div className='h400'>
              You’ve reached your active study limit of <span className='font-bold'>{limits.studies}</span>. Please
              upgrade your plan for unlimited access. <AlertLink href='/plans'>View membership plans</AlertLink>
            </div>
          </Alert>
        )}
        <Alert
          heading='We’ve re-built the Studies page to improve performance'
          className='mb-4'
          type='promo'
          dismissKey='paginated-studies-index'
        >
          <div className='h400'>The new paginated experience should result in faster loading times.</div>
        </Alert>
        <div className='flex items-center mb-4'>
          <Text color='gray-500' h='400'>
            {pluralize('studies', data?.meta?.count || 0, true)}
          </Text>
          <div className='flex-1 mx-4'>{isFetching && <Spinner className='w-4 h-4' />}</div>
          <Paginator
            canClickNext={canNextPage}
            canClickPrevious={canPreviousPage}
            onClickNext={nextPage}
            onClickPrevious={previousPage}
            current={currentPage}
            total={totalPages}
          />
        </div>
        {!!studies.length && renderStudies}
      </DashboardLayoutBody>
    </DashboardLayout>
  );
};
