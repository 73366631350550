import { Toast } from '@stores/toaster';

export const failedUpdate = (): Toast => ({
  heading: 'Failed to update study!',
  icon: 'error',
  text: 'Please try again in a few moments, or contact support.'
});

export const successUpdateTitle = (): Toast => ({
  heading: 'Updated',
  icon: 'success',
  text: 'Study title was updated'
});

export const successUpdate = (): Toast => ({
  heading: 'Updated successfully!',
  icon: 'success',
  text: 'Your study was updated.'
});

export const failedUpdateEmail = (): Toast => ({
  heading: 'Failed to save email!',
  icon: 'error',
  text: 'Please try again in a few moments, or contact support.'
});

export const failedPublish = (step: 'activate' | 'invites', message?: string): Toast => ({
  heading: 'Failed to publish study!',
  icon: 'error',
  text: {
    activate: `Something went wrong while trying to activate this study. ${message ? message : 'Please try again.'}`,
    invites: 'Something went wrong while scheduling your invite emails.'
  }[step]
});

export const failedSetDuration = (): Toast => ({
  heading: 'Failed to set duration!',
  icon: 'error',
  text: 'Study duration in minutes should be a positive number.'
});

export const successUpdateProfile = (): Toast => ({
  heading: 'Profile updated!',
  icon: 'success',
  text: 'The profile was updated.'
});
