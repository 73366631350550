import * as React from 'react';

import { Alert, Button, SlideOut, Text, Tooltip } from '@components/common';
import pluralize from 'pluralize';

type Props = {
  isSubmitting: boolean;
  customerImport: CustomerImport;
  onClose: () => void;
  onSubmit: () => void;
};

export const ReviewSlideout: React.VFC<Props> = ({ isSubmitting, customerImport, onClose, onSubmit }) => (
  <SlideOut
    size='lg'
    title='Confirm and import'
    subtitle='This is the summary of your current import'
    onClose={onClose}
    renderFooter={() => (
      <div className='flex justify-end'>
        <Button primary onClick={onSubmit} loading={isSubmitting}>
          Confirm & import
        </Button>
        <Button className='ml-2' onClick={onClose} disabled={isSubmitting}>
          Don’t import
        </Button>
      </div>
    )}
  >
    <hr className='border-t border-gray-200' />
    <div className='px-6 py-4'>
      {customerImport.invalid_candidates_count > 0 && (
        <Alert type='warning' className='mb-4'>
          <Text bold mb='1'>
            {pluralize('invalid candidate', customerImport.invalid_candidates_count, true)}
          </Text>
          <Text>You have candidates that will be imported, but can’t be contacted due to invalid emails.</Text>
        </Alert>
      )}

      <Text bold mb='4'>
        Summary
      </Text>

      <div className='flex items-center mb-4'>
        <Text h='400'>New candidates</Text>
        <Tooltip className='ml-1' content='New candidates are people not in your database yet.' />
        <Text h='400' className='flex-1 ml-1 text-right'>
          {customerImport.new_candidates_count}
        </Text>
      </div>

      <div className='flex items-center mb-4'>
        <Text h='400'>Existing candidates</Text>
        <Tooltip className='ml-1' content='Existing candidates already exist in your database.' />
        <Text h='400' className='flex-1 ml-1 text-right'>
          {customerImport.existing_candidates_count}
        </Text>
      </div>

      {!!customerImport.project_id && (
        <div className='flex items-center mb-4'>
          <Text h='400'>Shortlisted candidates</Text>
          <Tooltip className='ml-1' content='These are candidates who will be shortlisted to your study.' />
          <Text h='400' className='flex-1 ml-1 text-right'>
            {customerImport.shortlisted_candidates_count}
          </Text>
        </div>
      )}

      {customerImport.duplicate_candidates_count > 0 && (
        <div className='flex items-center mb-4'>
          <Text h='400'>Duplicate candidates</Text>
          <Tooltip
            className='ml-1'
            content={`These rows will be skipped because they have the same ${customerImport.id_attr} as other rows in the table.`}
          />
          <Text h='400' className='flex-1 ml-1 text-right'>
            {customerImport.duplicate_candidates_count}
          </Text>
        </div>
      )}

      <hr className='mb-6 border-t border-gray-200' />

      <Text bold mb='4'>
        Import settings
      </Text>

      <ul className='h400 list-disc list-inside'>
        <li className='mb-4'>
          <span>
            <strong>Import name:</strong> {customerImport.name || '(Untitled)'}
          </span>
        </li>
        <li className='mb-4'>
          <strong>Attribute update process:</strong>{' '}
          {customerImport.update_existing ? 'Update existing candidates' : 'Don’t overwrite existing'}
        </li>
        <li className='mb-4'>
          <strong>Contact access:</strong>{' '}
          {customerImport.contact_access === 'public'
            ? 'Anyone on the workspace'
            : pluralize('team', customerImport.team_ids.length, true)}
        </li>
      </ul>
    </div>
  </SlideOut>
);
