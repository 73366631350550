import { PageTitle } from 'components/common/helmets';
import { useStudyPlan } from 'components/StudiesApp/components/StudyDraft/hooks/useStudyPlan';
import { Calendar, ExternalSurvey, Plan } from 'components/StudiesApp/components/StudyDraft/pages';
import * as toasts from 'components/StudiesApp/components/StudyDraft/toasts';
import { useBrand } from 'hooks/useBrand';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useMatch, useParams } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { RepositoryApp } from '@components/RepositoryApp/RepositoryApp';
import { StudyTabs } from '@components/StudiesApp/components/StudyPublished/components/StudyTabs';
import { SynthesisTab } from '@components/StudiesApp/components/StudyPublished/components/SynthesisTab';
import { TagsTab } from '@components/StudiesApp/components/StudyPublished/components/TagsTab';
import { useStudyBookability } from '@components/StudyMessages/hooks/useStudyBookability';
import { track } from '@components/tracking';
import { useDeviceType } from '@hooks/useDeviceType';
import { usePermission } from '@hooks/usePermission';
import { useSidebar } from '@hooks/useSidebar';
import { useUser } from '@hooks/useUser';
import { useShowAiChatBubble } from '@stores/aiChat';
import { useToaster } from '@stores/toaster';
import { useCollectionView } from '@stores/view';
import { ApprovalRequestBanner } from './components/ApprovalRequestBanner';
import { AutomationSettings } from './components/AutomationSettings';
import { Details, Header } from './components/Header';
import { NotificationSettings } from './components/NotificationSettings';
import { ScreenerResultsTab } from './components/ScreenerResultsTab';
import { ScreenerTab } from './components/ScreenerTab';
import { StatsDetail, StatsIndex } from './components/Stats';
import { TemplateSettings } from './components/TemplateSettings';
import { buildTabs, StudyTabKey } from './helpers/buildTabs';
import { Emails, ExternalCandidatesRequest, ExternalCandidatesRequests, Incentives, Overview, Task } from './pages';
import { Pages } from './pages/Pages';
import { ParticipantsTab } from './components/ParticipantsTab';
import { ParticipantsTabServerSide } from './components/ParticipantsTab/ParticipantsTabServerSide';
import { buildTabLabels } from './utils';
import { Provider as CollectionView } from 'components/stores/view';
import { useFeature } from '@hooks/useFeature';

interface Props {
  study: Study;
  refetch: () => void;
}

export const StudyShow: React.FC<Props> = ({ study, refetch }) => {
  useBrand();
  useShowAiChatBubble();

  const serverSideParticipations = useFeature('serverside_participations');

  const [loading, setLoading] = useState<boolean>(false);
  const [backgroundTasks, setBackgroundTasks] = useState<BackgroundTask[]>([]);

  // TODO: get rid of this state
  const [saving, setSaving] = useState<boolean>(false);

  const { isMobile } = useDeviceType();

  const { view, setView } = useCollectionView();

  const [updateStudy, { isLoading: updateLoading, isError: updateError }] = api.useUpdateStudyMutation();
  const { data: approvalRequests } = api.useGetApprovalRequestsQuery(
    { id: study.id },
    {
      skip: study.state != 'pending'
    }
  );
  const approvalRequest = approvalRequests?.[0];

  const [planOptions, setPlanOptions] = useStudyPlan(study, updateStudy);

  const match = useMatch('/studies/:id/:tab/*');

  const params = useParams<{ id: string }>();

  const location = useLocation();

  const showToast = useToaster();
  const user = useUser();
  const canUpdate = usePermission<Study>('updateStudy')(study);

  useEffect(() => {
    track('viewed_study', {
      is_creator: study.creator_id == user.id,
      is_owner: study.owner_id == user.id,
      state: study.state,
      style: study.style,
      sample: study.sample
    });
  }, []);

  const sidebar = useSidebar();

  useEffect(() => {
    if (!isMobile) {
      sidebar.setOpen(false);
    }
  }, [isMobile]);

  const hasExternalCandidatesAccess = study.external_candidates_enabled;

  const { hasError, hasWarning } = useStudyBookability({ studyId: study.id, event: 'calendar' });

  const TABS = useMemo(() => {
    const tabs = buildTabs(study, canUpdate, hasExternalCandidatesAccess).map((tab) => {
      if (tab.key === 'calendar') {
        return { ...tab, errored: hasError, warning: hasWarning };
      }
      return tab;
    });

    return tabs;
  }, [study, hasError, hasWarning]);

  useEffect(() => {
    if (updateError) {
      showToast(toasts.failedUpdate());
    }
  }, [updateError]);

  const { style, focus_group, on_gq, pre_screener, survey_screener } = study;

  const pageProps: PageProps = {
    study,
    pre_screener: pre_screener,
    survey_screener: survey_screener,
    landing_page: study.landing_page,
    saving,
    setSaving,
    onSave: updateStudy
  };

  useEffect(() => {
    if (TABS.find((tab) => tab.key === view.tab)) {
      setView({ tab: match?.params.tab });
    }
  }, [match?.params.tab]);

  const renderRouterFallback = () => {
    // when creating a new study we need to navigate to its page
    // this Navigate component won't let that happen
    // so we should only render it if the id on the url matches the study id
    if (params?.id && +params.id === study.id) {
      return (
        <Route
          path='*'
          element={
            <Navigate
              replace
              to={{
                pathname: TABS.find((tab) => tab.key === view.tab) ? view.tab : `participants`,
                hash: location.hash,
                search: location.search
              }}
            />
          }
        />
      );
    }
  };

  return (
    <div className='flex flex-col h-full'>
      {approvalRequest && <ApprovalRequestBanner request={approvalRequest} />}
      <PageTitle>{study.title}</PageTitle>
      <Header
        study={study}
        updateStudy={updateStudy}
        setLoading={setLoading}
        isUpdating={updateLoading}
        canEdit={canUpdate}
        approvalRequest={approvalRequest}
      />

      {study.style !== 'panel' && <Details study={study} />}

      <div className='xx-study-tab tablet:flex-row relative flex flex-col flex-1 overflow-hidden'>
        <StudyTabs
          withRepo={!study.focus_group}
          current={(match?.params.tab as StudyTabKey) || undefined}
          tabs={TABS}
          labels={buildTabLabels(study.style)}
        />
        <div className='h-screen-with-fixed-header flex flex-col flex-1 overflow-auto'>
          <Routes>
            {study.state === 'draft' && (
              <Route path='*' element={<Navigate replace to={`/studies/${study.id}/edit`} />} />
            )}
            <Route path='incentives' element={<Incentives {...pageProps} />} />
            <Route
              path='plan'
              element={<Plan options={planOptions} participations={[]} setOptions={setPlanOptions} {...pageProps} />}
            />
            <Route path='overview' element={<Overview study={study} />} />
            {!study.focus_group && (
              <>
                <Route
                  path='repository/*'
                  element={
                    <RepositoryApp
                      study={study}
                      setBackgroundTasks={(backgroundTask) => {
                        setBackgroundTasks([...backgroundTasks, backgroundTask]);
                      }}
                    />
                  }
                />
                <Route path='tags' element={<TagsTab study={study} />} />
                <Route path='synthesis' element={<SynthesisTab study={study} />} />
              </>
            )}
            <Route
              path='stats/:event'
              element={
                <StatsDetail study={study} backgroundTasks={backgroundTasks} setBackgroundTasks={setBackgroundTasks} />
              }
            />
            <Route path='stats' element={<StatsIndex study={study} />} />
            <Route path='task' element={<Task {...pageProps} />} />
            <Route
              path='calendar'
              element={<Calendar {...pageProps} studyShowTitle refetch={refetch} isLoading={loading} />}
            />
            <Route
              path='survey/*'
              element={
                <>
                  {study.on_gq ? (
                    <ScreenerTab study={study} pathType={'survey'} screenerType={'survey'} updateStudy={updateStudy} />
                  ) : (
                    <div className='px-page py-gutter'>
                      <ExternalSurvey study={study} onSave={pageProps.onSave} />
                    </div>
                  )}
                </>
              }
            />
            <Route
              path='screener_responses/*'
              element={
                <ScreenerResultsTab
                  study={study}
                  setBackgroundTasks={(backgroundTask) => {
                    setBackgroundTasks([...backgroundTasks, backgroundTask]);
                  }}
                />
              }
            />
            <Route
              path='test/*'
              element={
                <ScreenerTab study={study} pathType={'test'} screenerType={'survey'} updateStudy={updateStudy} />
              }
            />
            <Route
              path='screener/*'
              element={
                <ScreenerTab study={study} pathType={'screener'} screenerType={'pre'} updateStudy={updateStudy} />
              }
            />
            <Route path='emails/*' element={<Emails {...pageProps} />} />
            <Route path='pages/*' element={<Pages {...pageProps} />} />
            <Route
              path='participants'
              element={
                <CollectionView
                  scope='participations'
                  page={study.id.toString()}
                  default={{
                    sort: { value: '', desc: false },
                    filters: '',
                    layout: 'grid'
                  }}
                >
                  {serverSideParticipations ? (
                    <ParticipantsTabServerSide
                      {...pageProps}
                      backgroundTasks={backgroundTasks}
                      setBackgroundTasks={setBackgroundTasks}
                      refetch={refetch}
                    />
                  ) : (
                    <ParticipantsTab
                      {...pageProps}
                      backgroundTasks={backgroundTasks}
                      setBackgroundTasks={setBackgroundTasks}
                      refetch={refetch}
                    />
                  )}
                </CollectionView>
              }
            />
            <Route path='recruitment_requests' element={<ExternalCandidatesRequests study={study} />} />
            <Route
              path='recruitment_requests/:externalCandidatesRequestId'
              element={<ExternalCandidatesRequest study={study} />}
            />
            <Route path='automation' element={<AutomationSettings study={study} />} />
            <Route path='notifications' element={<NotificationSettings study={study} />} />
            {study.style == 'video_call' && (
              <Route path='interview_guide' element={<TemplateSettings study={study} />} />
            )}
            {renderRouterFallback()}
          </Routes>
        </div>
      </div>
    </div>
  );
};
