import { api } from 'api/reduxApi';
import cn from 'classnames';
import { useToaster } from 'components/stores/toaster';
import * as toasts from 'components/StudiesApp/components/StudyDraft/toasts';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { CaretSVG } from '@components/svgs';
import { useAccount } from '@hooks/useAccount';

import { UseStudyUsersHook } from '../hooks/useStudyUsers';
import { Moderator } from './Moderator';
import { UserCombobox } from 'components/shared/UserCombobox';

interface Props {
  disabled?: boolean;
  studyUsersHook: UseStudyUsersHook;
  study: Study;
  type: NonNullable<StudyUser['role']>;
  requireCalendar?: boolean;
  studyUserColors: Record<number, UserColor | undefined>;
}
export const CallModerators: React.FC<Props> = ({
  disabled,
  studyUsersHook: { loading, calendarState, team, allStudyUsers },
  study,
  type,
  requireCalendar = false,
  studyUserColors
}) => {
  const usersToShow = useMemo(() => {
    if (type === 'moderator') {
      return allStudyUsers?.filter(
        (u) => u.studyUser.role === 'moderator' && (!u.teamUser.deactivated || !study.user_ids.includes(u.teamUser.id))
      );
    }
    if (type === 'observer') {
      return allStudyUsers?.filter((u) => u.studyUser.role === 'observer' && !u.teamUser.deactivated);
    }
  }, [allStudyUsers, study.user_ids]);

  const {
    refresh: refreshAccount,
    account: { domain: accountDomain }
  } = useAccount();

  const [updating, setUpdating] = useState(false);
  const [showAll, setShowAll] = useState(!!usersToShow?.length && usersToShow?.length <= 3);

  const defaultVisibleUsers = usersToShow?.length && usersToShow?.length > 3 ? usersToShow.slice(0, 3) : usersToShow;

  const [currentUsers, setCurrentUsers] = useState<UseStudyUsersHook['allStudyUsers'] | undefined>(defaultVisibleUsers);

  const showToast = useToaster();

  const role: StudyUser['role'] = type === 'moderator' ? 'moderator' : 'observer';

  const [createStudyUser] = api.useCreateStudyUserMutation();
  const [deleteStudyUser] = api.useDeleteStudyUserMutation();
  const [updateStudyUser] = api.useUpdateStudyUserMutation();

  const onAddUser = async (id: number) => {
    setUpdating(true);
    try {
      if (study.user_ids.includes(id)) {
        const teamUser = userSelectOptions.find((u) => u.id === id) as TeamUser;
        const newUser = { teamUser, studyUser: { user_id: id, role, study_id: study.id } as StudyUser };

        if (teamUser) {
          setCurrentUsers([...(currentUsers || []), newUser]);
        }

        await updateStudyUser({ study_id: study.id, user_id: id, role }).unwrap();
      } else {
        const teamUser = team.find((u) => u.id === id) as unknown as TeamUser;
        const newUser = { teamUser, studyUser: { user_id: id, role, study_id: study.id } as StudyUser };

        if (newUser) {
          setCurrentUsers([...(currentUsers || []), newUser]);
        }

        await createStudyUser({ study_id: study.id, user_id: id, role }).unwrap();
      }
    } catch {
      setCurrentUsers(currentUsers);
      showToast(toasts.failedUpdate());
    }
    setUpdating(false);
  };

  const onAddPhantomUser = async (email: string) => {
    setUpdating(true);
    try {
      await createStudyUser({ study_id: study.id, email, role }).unwrap();

      const newUser = {
        teamUser: { email, role, study_id: study.id, is_phantom: true } as unknown as TeamUser,
        studyUser: { role, study_id: study.id } as StudyUser
      };

      setCurrentUsers([...(currentUsers || []), newUser]);
      await refreshAccount();
    } catch {
      setCurrentUsers(currentUsers);
      showToast(toasts.failedUpdate());
    }
    setUpdating(false);
  };

  const onRemoveUser = async (id: number) => {
    setUpdating(true);
    setCurrentUsers(currentUsers?.filter((u) => u.studyUser.user_id !== id));
    try {
      await deleteStudyUser({ study_id: study.id, user_id: id }).unwrap();
    } catch {
      setCurrentUsers(currentUsers);
      showToast(toasts.failedUpdate());
    }
    setUpdating(false);
  };

  const onChangeCalendarId = async (id: number, nylas_calendar_id: string) => {
    setUpdating(true);
    try {
      await updateStudyUser({ study_id: study.id, user_id: id, nylas_calendar_id }).unwrap();
    } catch {
      showToast(toasts.failedUpdate());
    }
    setUpdating(false);
  };

  const userSelectOptions = useMemo(() => {
    if (!allStudyUsers) {
      return [];
    }
    const moderatorIds = allStudyUsers.filter((u) => u.studyUser.role != null).map((m) => m.studyUser.user_id);

    return team.filter((u) => {
      if (u.deactivated) return false;
      if (moderatorIds.includes(u.id)) return false;
      if (requireCalendar && !calendarState.calendars[u.id]) return false;
      if (type === 'moderator' && u.role && !['admin', 'creator'].includes(u.role)) return false;

      return true;
    });
  }, [allStudyUsers, currentUsers, team, study.user_ids]);

  useEffect(() => {
    const owner = userSelectOptions.find((user) => user.id === study.owner_id);
    if (owner) {
      onAddUser(owner.id);
    }
  }, [userSelectOptions]);

  useEffect(() => {
    setCurrentUsers(showAll ? usersToShow : usersToShow?.slice(0, 3));
  }, [showAll, usersToShow]);

  return (
    <div>
      <div className='mb-2'>
        <UserCombobox
          disabled={loading || updating || disabled}
          placeholder='Enter name or email…'
          acceptRawEmails
          onSelect={(items) => {
            for (const item of items) {
              if (item.type === 'user') {
                onAddUser(item.user.id);
              } else if (type === 'observer') {
                onAddPhantomUser(item.email);
              }
            }
          }}
          context={{ study_id: study.id, role: type }}
          accountDomain={accountDomain}
        />
      </div>
      <div className='flex flex-col'>
        {loading && (
          <div>
            <Skeleton className='bg-gray-50 h-12' count={1} />
          </div>
        )}
        {!loading &&
          currentUsers?.map(({ teamUser: user, studyUser }, i) => (
            <Moderator
              studyUserColors={studyUserColors}
              type={type}
              key={user.id}
              disabled={Boolean(updating || disabled)}
              user={user}
              isOwner={user.id === study.owner_id}
              onClickRemove={() => onRemoveUser(user.id)}
              calendarId={studyUser.nylas_calendar_id}
              onChangeCalendarId={(id) => onChangeCalendarId(user.id, id)}
              loading={calendarState.loading || loading}
              error={calendarState.error}
              calendars={calendarState.calendars[user.id]}
              requireCalendar={requireCalendar}
            />
          ))}

        {usersToShow && usersToShow.length > 3 && (
          <button className='h400 mt-2' onClick={() => setShowAll(!showAll)}>
            {showAll ? <span>Show less</span> : <span>Show all</span>}
            <CaretSVG className={cn('ml-2 inline transform', { 'rotate-90': !showAll, '-rotate-90': showAll })} />
          </button>
        )}
      </div>
    </div>
  );
};
