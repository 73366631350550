import * as React from 'react';
import { useMemo, useState } from 'react';

import { bulkParticipationsAction, sendStudyMessage } from '@api/queries';
import { api } from '@api/reduxApi';
import { Loading, Text } from '@components/common';
import { Toast, useToaster } from '@components/stores/toaster';
import { incentiveStr } from '@components/StudiesApp/components/StudyPublished/helpers/incentiveStr';
import { humanize } from '@components/utils';

import { StudyMessageSlideOut } from './StudyMessageSlideOut';
import { filterCompleteable, filterContactable, filterPartiesById } from './utils/filters';
import { ServerFilterQuery } from '@components/CandidatesApp/CandidatesIndex/types';
import { useFeature } from '@hooks/useFeature';

const ThanksEmailInfo = ({ study, participation }) => {
  if (study.incentive_method) {
    return (
      <div className='pb-6'>
        <Text bold mb='2'>
          Reward
        </Text>
        <Text h='500'>
          {humanize(study.incentive_method)}: {incentiveStr(study, participation)}
        </Text>
        <Text h='400' className='mt-2'>
          Study: {study.title}
        </Text>
      </div>
    );
  } else {
    return <></>;
  }
};

interface Props {
  study: Study;
  parties?: Participation[];
  partyIds?: number[];
  messageOnly?: boolean;
  onSuccess: () => void;
  onClose: () => void;
  allSelected?: boolean;
  query?: ServerFilterQuery;
}

const toasts: { [key: string]: Toast } = {
  thanks: {
    heading: 'Thanks messages sent!',
    text: `Any reward will be included in the messages`,
    icon: 'success'
  },
  completed: {
    heading: `Successfully marked as completed!`,
    text: 'All participants were marked as completed.',
    icon: 'success'
  },
  error: {
    heading: 'Something went wrong',
    text: 'We couldn’t send that message. Please try again later.',
    icon: 'error'
  }
};

export const ThanksSlideOut: React.FC<Props> = ({
  study,
  parties: initialParties,
  partyIds,
  messageOnly,
  onSuccess,
  onClose,
  allSelected,
  query
}) => {
  const serversideParticipantsEnabled = useFeature('serverside_participations');

  const showToast = useToaster();
  const [loading, setLoading] = useState<boolean>(false);

  const { data: participations = [], isLoading: isLoadingParticipations } = api.useGetParticipationsQuery(
    { id: study.id },
    { skip: !!initialParties }
  );
  const fetchedParties = useMemo(() => {
    return partyIds ? filterPartiesById(participations as any, partyIds) : [];
  }, [participations]);

  const parties = initialParties || fetchedParties;

  const contactable = filterContactable(study.comms_medium, parties);

  async function handleSend({ message, send, sender }) {
    setLoading(true);
    if (!messageOnly) {
      const completablePartyIds = filterCompleteable(parties).map(({ id }) => id);
      await bulkParticipationsAction(study.id, {
        action: 'complete',
        ...(allSelected && serversideParticipantsEnabled ? { query } : { ids: completablePartyIds }),
        fields: []
      });
    }
    if (send) {
      const resp = await sendStudyMessage(
        { id: message.id, studyId: study.id },
        {
          ...(allSelected && serversideParticipantsEnabled
            ? { query }
            : { participation_ids: contactable.map(({ id }) => id) }),
          sender
        }
      );
      if (!resp) {
        showToast(toasts.error);
      } else {
        onSuccess?.();
        showToast(toasts.thanks);
      }
    } else {
      onSuccess?.();
      showToast(toasts.complete);
    }
    setLoading(false);
  }

  const title = messageOnly ? 'Send thanks' : 'Confirm and send thanks';
  const cta = messageOnly ? 'Send thanks' : 'Confirm & send';

  const optional = !messageOnly && study.incentive_method != 'tremendous';
  const [send, setSend] = useState(true);

  return (
    <StudyMessageSlideOut
      customizable
      optional={optional ? { send, setSend } : undefined}
      title={title}
      cta={cta}
      study={study}
      event='thanks'
      previewCandidate={parties[0]?.customer}
      previewParticipation={parties[0]}
      onClose={onClose}
      onSend={handleSend}
      inviteableCount={contactable.length}
    >
      <>
        {(loading || isLoadingParticipations) && <Loading absolute />}
        <ThanksEmailInfo study={study} participation={parties[0]} />
      </>
    </StudyMessageSlideOut>
  );
};
