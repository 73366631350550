import { useAccount } from 'hooks/useAccount';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { api } from '@api/reduxApi';
import {
  Button,
  Card,
  FormGroup,
  Input,
  InputWithAddons,
  Loading,
  SimpleConfirmationModal,
  Text
} from '@components/common';

import { StepHelper, StepTitle } from '../../shared';
import {
  ALL_INDUSTRIES_VALUE,
  DEFAULT_PROLIFIC_FILTER_IDS,
  DEFAULT_RESPONDENT,
  FILTER_PAST_PARTICIPATION_OPTIONS,
  MARKET_TYPE_OPTIONS,
  respondentFiltersObjects
} from '../constants';
import { CostInfo } from './CostInfo';
import { AudienceField } from '@components/StudiesApp/components/StudyPublished/pages/ExternalCandidatesRequest/components/AudienceField';
import * as icons from './svgs';
import { Label, PastParticipations } from './Inputs';
import { Filter, FilterStateLookup } from './FilterInput';
import { ErrorMessage } from '@components/common/ErrorMessage';
import { mapToExternalCandidatesFilters, requestAttrsToFilters } from './../utils';
import { ProlificFilters } from '@components/StudiesApp/components/StudyPublished/pages/ExternalCandidatesRequest/components/ProlificFilters';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { SingleUserSVG } from '@components/svgs';
import cn from 'classnames';

type RequestAction = 'save' | 'publish';
type RequestModal = 'cancel';

interface Props {
  request?: ExternalCandidatesRequest;
  study: Study;
  sidebar?: boolean;
  isLoading?: boolean;
  onChange?: (payload: ExternalCandidatesRequest) => void;
  onSave?: (payload: ExternalCandidatesRequest) => void;
  onCancel?: () => void;
}

export const ExternalCandidatesRequestForm: React.FC<Props> = ({
  study,
  request,
  sidebar = true,
  onChange,
  onSave,
  onCancel,
  isLoading = false
}) => {
  const editing: boolean = !!request?.id;
  const limitedEditing = !!request?.been_published;
  const { account } = useAccount();
  const accountName = account.name;

  const shouldShowSaveButtons: boolean = !!onSave;

  const [confirmationModal, setConfirmationModal] = useState<RequestModal | null>(null);

  const requestId = request?.id;

  const defaultRequestState: ExternalCandidatesState = request?.state || 'draft';
  const defaultPlatformName = request?.platform_name || 'Respondent';
  const defaultValues = request?.attrs || {
    public_title: '',
    public_description: '',
    maximum_candidates: study.maximum_slots
  };

  const respondentDefaultValues =
    request && request.platform_name == 'Respondent'
      ? { ...DEFAULT_RESPONDENT, ...request.attrs.respondent }
      : { ...DEFAULT_RESPONDENT };

  const defaultMaximumCandidates: string = (defaultValues.maximum_candidates || 0).toString();

  const [changed, setChanged] = useState<boolean>(false);
  const [requestState, setRequestState] = useState<ExternalCandidatesState>(defaultRequestState);
  const [platformName, setPlatformName] = useState<ExternalCandidatesPlatformName>(defaultPlatformName);
  const [publicTitle, setPublicTitle] = useState<string>(defaultValues.public_title);
  const [publicDescription, setPublicDescription] = useState<string>(defaultValues.public_description);
  const [maximumCandidates, setMaximumCandidates] = useState<string>(defaultMaximumCandidates);
  const [filtersInitialized, setFiltersInitialized] = useState<boolean>(false);

  const [filters, setFilters] = useState<FilterStateLookup>(requestAttrsToFilters(request) as FilterStateLookup);
  const [filterIds, setFilterIds] = useState<string[]>(DEFAULT_PROLIFIC_FILTER_IDS);

  const [filterPastParticipation, setFilterPastParticipation] = useState<ExternalCandidatesFilterPastParticipation>(
    (respondentDefaultValues.filter_past_participation?.toString() || '') as ExternalCandidatesFilterPastParticipation
  );

  const marketType = filters['marketType']?.selected_values?.[0] as ExternalCandidatesMarketType;

  const { data: prolificFiltersObjects } = api.useGetProlificFiltersQuery(
    platformName === 'Respondent' ? skipToken : undefined
  );

  let filtersObjects: ExternalCandidatesFilterListResponse[] = [];
  if (platformName == 'Prolific') filtersObjects = prolificFiltersObjects || [];
  if (platformName == 'Respondent') filtersObjects = respondentFiltersObjects;

  const placeholderMaximumCandidates = 10;

  const filterPastParticipationOptions = FILTER_PAST_PARTICIPATION_OPTIONS.map(({ label, value }) => ({
    label: label.replace('your account', accountName),
    value
  }));

  const { data: availableIndustries } = api.useGetRespondentIndustriesQuery();
  const industryOptions = (availableIndustries || []).map(({ name, id }) => ({
    label: name,
    value: id
  }));

  const { data: availableJobTitles } = api.useGetRespondentJobTitlesQuery({});
  const jobTitleOptions = (availableJobTitles || []).map(({ name, id }) => ({
    label: name,
    value: id
  }));

  const { data: availableSkills } = api.useGetRespondentSkillsQuery({});
  const skillOptions = (availableSkills || []).map(({ name, id }) => ({
    label: name,
    value: id
  }));

  const { data: availableTopics } = api.useGetRespondentTopicsQuery({});
  const topicOptions = (availableTopics || []).map(({ name, id }) => ({
    label: name,
    value: id
  }));

  useEffect(() => {
    setFiltersInitialized(!!filtersObjects);
  }, [filtersObjects]);

  useEffect(() => {
    if (request?.platform_name === 'Prolific' && request.attrs.prolific?.filters) {
      setFilterIds(request.attrs.prolific.filters.map((filter) => filter.filter_id));
    }
  }, []);

  useEffect(() => {
    if (marketType === 'b2b') setPlatformName('Respondent');
    if (marketType === 'b2c') setPlatformName(defaultPlatformName);
  }, [marketType]);

  useEffect(() => {
    if (request?.state) {
      setRequestState(request?.state);
    }
  }, [request]);

  const buildPayload = (
    publishing?: boolean
  ): ExternalCandidatesRequest & {
    style?: Study['style'];
    incentive?: number;
  } => {
    let baseRespondent: { filter_past_participation?: number } = {};
    if (filterPastParticipation) {
      baseRespondent = { ...baseRespondent, filter_past_participation: Number(filterPastParticipation) };
    }

    return {
      id: requestId,
      project_id: study.id,
      state: publishing ? 'submitted_publish' : requestState,
      platform_name: platformName,
      attrs: {
        market_type: marketType,
        public_title: publicTitle,
        public_description: publicDescription,
        maximum_candidates: Number(maximumCandidates),
        prolific:
          platformName === 'Prolific'
            ? {
                filters: mapToExternalCandidatesFilters(filters)
              }
            : {},
        respondent:
          platformName === 'Respondent'
            ? marketType === 'b2b'
              ? {
                  ...baseRespondent,
                  filters: mapToExternalCandidatesFilters(filters),
                  industries:
                    filters['targetIndustries']?.selected_values?.map((v) => {
                      const name = industryOptions.find((i) => i.value === v)?.label;
                      return { name: name as string, id: v };
                    }) || [],
                  job_titles:
                    filters['targetJobTitles']?.selected_values?.map((v) => {
                      const name = jobTitleOptions.find((i) => i.value === v)?.label;
                      return { name: name as string, id: v };
                    }) || [],
                  skills:
                    filters['targetSkills']?.selected_values?.map((v) => {
                      const name = skillOptions.find((i) => i.value === v)?.label;
                      return { name: name as string, id: v };
                    }) || []
                }
              : {
                  ...baseRespondent,
                  filters: mapToExternalCandidatesFilters(filters),
                  topics:
                    filters['targetTopics']?.selected_values?.map((v) => {
                      const name = topicOptions.find((i) => i.value === v)?.label;
                      return { name: name as string, id: v };
                    }) || []
                }
            : {}
      }
    };
  };

  useEffect(() => {
    if (changed && onChange) {
      onChange(buildPayload());
      setChanged(false);
    }
  }, [changed]);

  const handleSave = (action: RequestAction) => {
    onSave?.(buildPayload(action === 'publish'));
  };

  const storeFilterValues = (filtersToStore: Filter[]) => {
    let updatedFilters = filters;
    filtersToStore.forEach(({ filter_id, selected_values }) => {
      updatedFilters = {
        ...updatedFilters,
        [filter_id]: {
          type: 'select',
          selected_values: selected_values,
          selected_range: { lower: 0, upper: 0 }
        }
      };
    });
    setFilters(updatedFilters);
  };

  const storeFilterRange = (filtersToStore: Filter[]) => {
    let updatedFilters = filters;
    filtersToStore.forEach(({ filter_id, selected_range }) => {
      updatedFilters = {
        ...updatedFilters,
        [filter_id]: {
          type: 'range',
          selected_values: [],
          selected_range: selected_range
        }
      };
    });
    setFilters(updatedFilters);
  };

  function renderFields(): JSX.Element {
    return (
      <div className='w-full'>
        <section className='p-6 pb-0 border-b border-gray-200'>
          <Text className='h300 bold uppercase mb-6'>General</Text>
          <FormGroup key='maximumCandidates'>
            <Label desc='How many participants you are looking to recruit with this request (up to 150)'>
              Participants to recruit
            </Label>
            <div className='w-full tablet:w-1/2'>
              <InputWithAddons
                type='number'
                ariaLabel='Participant limit'
                className='no_arrows h-10'
                value={maximumCandidates}
                onChange={(v: string) => {
                  setMaximumCandidates(v);
                  setChanged(true);
                }}
                icon={<SingleUserSVG />}
                prefix=''
                placeholder={placeholderMaximumCandidates.toString()}
                suffix='people'
                pr='16'
                error={!(Number(maximumCandidates) > 0) || Number(maximumCandidates) > study.maximum_slots}
                onEnter={(e) => e.currentTarget.blur()}
              />
            </div>
            {!(Number(maximumCandidates) > 0) && (
              <ErrorMessage className='mt-2'>The value must be at least 1.</ErrorMessage>
            )}
            {Number(maximumCandidates) > study.maximum_slots && (
              <ErrorMessage className='mt-2'>Exceeds the maximum slots.</ErrorMessage>
            )}
            {Number(maximumCandidates) > 150 && (
              <ErrorMessage className='mt-2'>Exceeds the maximum of 150.</ErrorMessage>
            )}
            {limitedEditing && platformName === 'Respondent' && maximumCandidates > defaultMaximumCandidates && (
              <ErrorMessage className='mt-2'>
                Note: The limit can be increased, but Respondent may not recruit additional participants.
              </ErrorMessage>
            )}
            {limitedEditing && maximumCandidates < defaultMaximumCandidates && (
              <ErrorMessage className='mt-2'>The limit can only be increased.</ErrorMessage>
            )}
          </FormGroup>
          <FormGroup key='publicTitle'>
            <Label desc='Public name or title of the recruiting request'>Request title</Label>
            <Input
              className='w-full'
              onChange={(v) => {
                setPublicTitle(v);
                setChanged(true);
              }}
              value={publicTitle}
              required
              ariaLabel='Public title'
              placeholder='Enter title...'
              error={!publicTitle || publicTitle.length > 100}
            />
            {!publicTitle && <ErrorMessage className='mt-2'>Please enter a title. 100 character limit.</ErrorMessage>}
            {publicTitle.length > 100 && (
              <ErrorMessage className='mt-2'>Exceeds the maximum length of 100 characters.</ErrorMessage>
            )}
          </FormGroup>
          <FormGroup key='publicDescription'>
            <Label desc='Description of the recruiting request for the participants to read before starting'>
              Request description
            </Label>
            <Input
              className='w-full text-sm border-gray-200 rounded-md'
              onChange={(v) => {
                setPublicDescription(v);
                setChanged(true);
              }}
              value={publicDescription}
              aria-label={'Public description'}
              required
              placeholder='Enter description...'
              error={!publicDescription || publicDescription.length > 300}
            />
            {!publicDescription && (
              <ErrorMessage className='mt-2'>Please enter a description. 300 character limit.</ErrorMessage>
            )}
            {publicDescription.length > 300 && (
              <ErrorMessage className='mt-2'>Exceeds the maximum length of 300 characters.</ErrorMessage>
            )}
          </FormGroup>
        </section>
        <section className='p-6 pt-4 border-b border-gray-200'>
          <Text className='h300 bold uppercase mb-6'>Audience</Text>
          <AudienceField
            title='Market type'
            subtitle='Select your target audience'
            icon={icons.Loupe}
            renderError={() => !marketType && <ErrorMessage className='mt-2' />}
            filters={filters}
            storeFilterValues={storeFilterValues}
            storeFilterRange={storeFilterRange}
            setChanged={setChanged}
            newRequest={!requestId}
            disabled={limitedEditing}
            object={{
              filter_id: 'marketType',
              title: 'Market type',
              type: 'radios',
              choices: MARKET_TYPE_OPTIONS.reduce((prev, curr) => ({ ...prev, [curr.value]: curr.label }), {}),
              min: 0,
              max: 0,
              data_type: 'ChoiceID'
            }}
          />
          {platformName === 'Prolific' && (
            <ProlificFilters
              filtersObjects={filtersObjects}
              disabled={limitedEditing}
              setChanged={setChanged}
              filterIds={filterIds}
              setFilterIds={setFilterIds}
              filtersInitialized={filtersInitialized}
              filters={filters}
              storeFilterValues={storeFilterValues}
              storeFilterRange={storeFilterRange}
            />
          )}
          {platformName === 'Respondent' && marketType === 'b2b' && (
            <>
              <AudienceField
                title='Job title'
                subtitle='A minimum of 1 job title selection is required and up to 10.'
                icon={icons.Case}
                filters={filters}
                storeFilterValues={storeFilterValues}
                storeFilterRange={storeFilterRange}
                setChanged={setChanged}
                newRequest={!requestId}
                disabled={limitedEditing}
                limit={10}
                object={{
                  filter_id: 'targetJobTitles',
                  title: 'Job title',
                  type: 'multiselect',
                  choices: jobTitleOptions?.reduce((prev, curr) => ({ ...prev, [curr.value]: curr.label }), {}),
                  min: 0,
                  max: 0,
                  data_type: 'ChoiceID'
                }}
                renderError={() =>
                  filters?.['targetJobTitles']?.selected_values?.length === 0 && (
                    <ErrorMessage className='mt-2'>Please add at least 1 job title.</ErrorMessage>
                  )
                }
              />
              <AudienceField
                title='Industries'
                subtitle='Select up to 5 industries or "All industries".'
                icon={icons.Charts}
                filters={filters}
                allOptionValue={ALL_INDUSTRIES_VALUE}
                storeFilterValues={storeFilterValues}
                storeFilterRange={storeFilterRange}
                setChanged={setChanged}
                limit={5}
                newRequest={!requestId}
                disabled={limitedEditing}
                object={{
                  filter_id: 'targetIndustries',
                  title: 'Industries',
                  type: 'multiselect',
                  choices: industryOptions?.reduce((prev, curr) => ({ ...prev, [curr.value]: curr.label }), {}),
                  min: 0,
                  max: 0,
                  data_type: 'ChoiceID'
                }}
                renderError={() =>
                  filters?.['targetIndustries']?.selected_values?.length === 0 && (
                    <ErrorMessage className='mt-2'>Please add at least 1 industry.</ErrorMessage>
                  )
                }
              />
              <AudienceField
                title='Skills'
                subtitle='Select up to 25 skills, knowledge areas, tools and/or certifications.'
                icon={icons.Loupe}
                filters={filters}
                limit={25}
                storeFilterValues={storeFilterValues}
                storeFilterRange={storeFilterRange}
                setChanged={setChanged}
                newRequest={!requestId}
                disabled={limitedEditing}
                object={{
                  filter_id: 'targetSkills',
                  title: 'Skills',
                  type: 'multiselect',
                  all_option: 'All skills',
                  choices: skillOptions?.reduce((prev, curr) => ({ ...prev, [curr.value]: curr.label }), {}),
                  min: 0,
                  max: 0,
                  data_type: 'ChoiceID'
                }}
              />
              <AudienceField
                title='Seniority'
                subtitle='Select specific criteria or "All Seniority".'
                icon={icons.Seniority}
                filters={filters}
                storeFilterValues={storeFilterValues}
                storeFilterRange={storeFilterRange}
                setChanged={setChanged}
                newRequest={!requestId}
                disabled={limitedEditing}
                object={filtersObjects?.find((o) => o.filter_id === 'seniority')}
              />
              <AudienceField
                title='Company Size'
                subtitle='Select specific criteria or "All company sizes".'
                icon={icons.CompanySize}
                filters={filters}
                storeFilterValues={storeFilterValues}
                storeFilterRange={storeFilterRange}
                setChanged={setChanged}
                newRequest={!requestId}
                disabled={limitedEditing}
                object={filtersObjects?.find((o) => o.filter_id === 'targetCompanySize')}
              />
            </>
          )}
          {platformName === 'Respondent' && marketType === 'b2c' && (
            <AudienceField
              title='Topics'
              subtitle='A minimum of 1 topic is required and up to 2.'
              icon={icons.Topics}
              filters={filters}
              limit={2}
              storeFilterValues={storeFilterValues}
              storeFilterRange={storeFilterRange}
              setChanged={setChanged}
              newRequest={!requestId}
              disabled={limitedEditing}
              object={{
                filter_id: 'targetTopics',
                title: 'Topics',
                type: 'multiselect',
                choices: topicOptions?.reduce((prev, curr) => ({ ...prev, [curr.value]: curr.label }), {}),
                min: 0,
                max: 0,
                data_type: 'ChoiceID'
              }}
              renderError={() =>
                filters?.['targetTopics']?.selected_values?.length === 0 && (
                  <ErrorMessage className='mt-2'>Please add at least 1 topic.</ErrorMessage>
                )
              }
            />
          )}
          {platformName === 'Respondent' && (
            <>
              <AudienceField
                title='Household Income'
                subtitle='Select specific criteria or "All incomes".'
                icon={icons.HouseholdIncome}
                filters={filters}
                storeFilterValues={storeFilterValues}
                storeFilterRange={storeFilterRange}
                setChanged={setChanged}
                newRequest={!requestId}
                disabled={limitedEditing}
                object={filtersObjects?.find((o) => o.filter_id === 'targetHouseholdIncome')}
              />
              <AudienceField
                title='Location'
                subtitle='Select up to 25 countries.'
                icon={icons.Location}
                limit={25}
                allOptionValue='all'
                filters={filters}
                storeFilterValues={storeFilterValues}
                storeFilterRange={storeFilterRange}
                setChanged={setChanged}
                newRequest={!requestId}
                disabled={limitedEditing}
                object={filtersObjects?.find((o) => o.filter_id === 'targetCountries')}
              />
              <AudienceField
                title='Gender'
                subtitle='Select specific criteria or "All genders".'
                icon={icons.Gender}
                filters={filters}
                storeFilterValues={storeFilterValues}
                storeFilterRange={storeFilterRange}
                setChanged={setChanged}
                newRequest={!requestId}
                disabled={limitedEditing}
                object={
                  {
                    ...filtersObjects?.find((o) => o.filter_id === 'targetGenders'),
                    all_option: 'All genders'
                  } as ExternalCandidatesFilterListResponse
                }
              />
              <AudienceField
                title='Education'
                subtitle='Select specific criteria or "All education".'
                icon={icons.Education}
                filters={filters}
                storeFilterValues={storeFilterValues}
                storeFilterRange={storeFilterRange}
                setChanged={setChanged}
                newRequest={!requestId}
                disabled={limitedEditing}
                object={filtersObjects?.find((o) => o.filter_id === 'targetEducation')}
              />
              <AudienceField
                title='Ethnicity'
                subtitle='Select specific criteria or "All ethnicities".'
                icon={icons.Ethnicity}
                filters={filters}
                storeFilterValues={storeFilterValues}
                storeFilterRange={storeFilterRange}
                setChanged={setChanged}
                newRequest={!requestId}
                disabled={limitedEditing}
                object={filtersObjects?.find((o) => o.filter_id === 'targetEthnicities')}
              />
              <AudienceField
                title='Age Range'
                subtitle='Select specific criteria or "All age ranges".'
                icon={icons.AgeRange}
                filters={filters}
                storeFilterValues={storeFilterValues}
                storeFilterRange={storeFilterRange}
                setChanged={setChanged}
                newRequest={!requestId}
                disabled={limitedEditing}
                object={filtersObjects?.find((o) => o.filter_id === 'targetAgeGroups')}
              />
              <PastParticipations
                onChange={(v) => {
                  setFilterPastParticipation(v);
                  setChanged(true);
                }}
                options={filterPastParticipationOptions}
                value={filterPastParticipation}
                disabled={limitedEditing}
                accountName={accountName}
              />
            </>
          )}
        </section>
      </div>
    );
  }

  return (
    <div className={cn(!shouldShowSaveButtons && 'pt-gutter')}>
      {!shouldShowSaveButtons && (
        <div className='text-center px-4'>
          <StepTitle>Set up your external recruiting</StepTitle>
          <StepHelper>If you’re not ready, you can skip ahead to complete other parts of the setup process.</StepHelper>
        </div>
      )}
      {shouldShowSaveButtons && (
        <div className='flex flex-row items-center justify-between pb-8'>
          <div className='mr-4'>
            <StepTitle>{editing ? 'Update' : 'New'} recruitment request</StepTitle>
            <StepHelper mb={'0'}>Submit recruitment requests to our external recruiting services</StepHelper>
          </div>
          <div className='space-x-4'>
            {onCancel && (
              <Button onClick={() => (changed ? setConfirmationModal('cancel') : onCancel())}>Cancel</Button>
            )}
            <Button onClick={() => handleSave('save')}>{editing ? 'Update request' : 'Save draft'}</Button>
            {!request?.been_published && (
              <Button primary onClick={() => handleSave('publish')}>
                Publish request
              </Button>
            )}
          </div>
          {onCancel && confirmationModal && confirmationModal === 'cancel' && (
            <SimpleConfirmationModal
              action='cancel without saving'
              onConfirm={onCancel}
              onCancel={() => setConfirmationModal(null)}
            />
          )}
        </div>
      )}
      {isLoading && <Loading absolute />}
      {sidebar ? (
        <div
          className={cn(
            'flex flex-col justify-center items-center tablet:items-start tablet:flex-row',
            !shouldShowSaveButtons && 'px-4 mx-auto'
          )}
        >
          {!shouldShowSaveButtons && <div className='w-80 hidden desktop:block mt-6 tablet:ml-6 tablet:mt-0' />}
          <div className={cn('w-full', !shouldShowSaveButtons ? 'max-w-2xl' : 'w-full tablet:w-9/12')}>
            <Card className='w-full' noPadding>
              {renderFields()}
            </Card>
          </div>
          <div className={cn('mt-6 tablet:ml-6 tablet:mt-0', !shouldShowSaveButtons ? 'w-80' : 'w-full tablet:w-3/12')}>
            <CostInfo
              platformName={platformName}
              marketType={marketType}
              b2bFee={study.external_fee_per_b2b_participant_in_cents / 100}
              b2cFee={study.external_fee_per_b2c_participant_in_cents / 100}
              candidates={Number(maximumCandidates) || placeholderMaximumCandidates}
            />
          </div>
        </div>
      ) : (
        <>{renderFields()}</>
      )}
    </div>
  );
};
