import { compact } from '@components/utils';

export const overDailyLimit = (dailyInviteLimit: DailyInviteLimit | undefined, count: number): boolean =>
  !!(
    dailyInviteLimit &&
    dailyInviteLimit.limit !== 0 &&
    dailyInviteLimit.current_count + count > dailyInviteLimit.limit
  );

export const bookabilityHasError = (bookability: Bookability = {}) =>
  Object.values(bookability).some(([level]) => level === 'error');
export const bookabilityHasWarning = (bookability: Bookability = {}) =>
  Object.values(bookability).some(([level]) => level === 'warning');

export const getErrorMessage = (style: Study['style'], event: StudyMessageEvent) => {
  if (event === 'invite' || event === 'invited_reminder') {
    switch (style) {
      case 'video_call':
        return 'Please enter a call-to-action button to direct participants to schedule a call.';
      case 'survey':
        return 'Please enter a call-to-action button to direct participants to complete the survey.';
      case 'unmoderated_test':
        return 'Please enter a call-to-action button to direct participants to complete the test.';
      case 'online_task':
        return 'Please enter a call-to-action button to direct participants to complete the test.';
      case 'panel':
        return 'Please enter a call-to-action button to direct participants to sign up.';
    }
  }

  if (event === 'thanks') {
    return 'Please enter a call-to-action button to direct participants to redeem the incentive.';
  }

  if (event === 'screener') {
    return 'Please enter a call-to-action button to direct participants to complete the screener.';
  }

  if (event === 'booked') {
    return 'Please enter a call-to-action button to direct participants to cancel or re-schedule, if needed.';
  }

  if (event === 'reschedule_request') {
    return 'Please enter a call-to-action button to direct participants to schedule a call.';
  }
};

export const isDateInPast = (time: Date): boolean => {
  const currentDate = new Date();
  const selectedDate = new Date(time);

  return currentDate.getTime() < selectedDate.getTime();
};

export const getReasonsManualBookingCantCreateEvents = (study: Study, parties: Participation[]) =>
  compact([parties.length !== 1 && 'Select only 1 participant to choose a time & create events']);
