import classNames from 'classnames';
import * as React from 'react';

import { Avatar, Card, DropdownLink, Spinner, Text, TippyOrNot } from '@components/common';
import { CaretDownSVG, CheckmarkInCircleSVG, ErrorSvg } from '@components/svgs';
import { usePopUp } from '@hooks/usePopUp';
import { useUser } from '@hooks/useUser';

import { EVENT_COLORS } from '../NylasEvent';
import { useUserCalendars } from '../../../hooks/useUserCalendars';
import { AuthButton } from '../../AuthButton';

interface Props {
  isOwner?: boolean;
  disabled?: boolean;
  loading?: boolean;
  error?: boolean;
  calendars?: NylasCalendar[] | null;
  user: TeamUser;
  calendarId?: string | null;
  onChangeCalendar?: (cal: NylasCalendar) => void;
  onChangeCalendarId?: (nylas_calendar_id: string) => void;
  onClickRemove: () => void;
  onClickSwitchToCalendly?: () => void;
  type: 'observer' | 'moderator';
  requireCalendar: boolean;
  studyUserColors: Record<number, UserColor | undefined>;
}
export const Moderator: React.FC<Props> = ({
  isOwner,
  user,
  disabled,
  loading,
  calendars: initialCalendars,
  calendarId,
  onChangeCalendar,
  onChangeCalendarId,
  onClickRemove,
  onClickSwitchToCalendly,
  type,
  requireCalendar,
  studyUserColors
}) => {
  const { id: currentUserId } = useUser();

  const { ref, togglePopUp, closePopUp, PopUp, open } = usePopUp();
  const { calendars } = useUserCalendars({ calendars: initialCalendars, userId: user.id });

  const handleClickRemove = () => {
    onClickRemove();
    closePopUp();
  };

  const handleClickCalendarId = (cal: NylasCalendar) => {
    onChangeCalendar?.(cal);
    onChangeCalendarId?.(cal.id);
    closePopUp();
  };

  const isSyncingWithNylas = false; // TODO

  const calendar = calendars?.find(({ id }) => id === calendarId);
  const [color, grade] = user.is_phantom ? ['gray', 400] : studyUserColors?.[user.id] || EVENT_COLORS[0];
  const hasDropdown = !disabled && !loading && (calendars || !isSyncingWithNylas);
  const isSelf = currentUserId === user.id;

  return (
    <div
      key={user.id}
      ref={ref}
      data-testid='moderator'
      className={classNames('xx-moderator cursor-pointer group relative flex items-center px-6 py-3 -mx-6', {
        'hover:bg-gray-50': !disabled
      })}
      onClick={hasDropdown ? togglePopUp : undefined}
    >
      <div className={classNames('mr-4 flex-shrink-0', `border-${color}-${grade} border-2 rounded-full`)}>
        <Avatar size='lg' user={user} />
      </div>
      <div className='flex-grow truncate'>
        <Text h='400' medium mb='0.5'>
          {user.name}
          {user.is_phantom && '*'}
        </Text>
        <div className='flex items-center space-x-1'>
          {!calendar && requireCalendar && !isSyncingWithNylas && <ErrorSvg className='w-4 h-4 text-red-600' />}
          {!calendar && requireCalendar && !isSyncingWithNylas && (
            <Text h='400' color='red-600'>
              Calendar is not selected.
            </Text>
          )}

          {!calendar && requireCalendar && isSyncingWithNylas && <Spinner className='w-4 h-4' />}
          {!calendar && requireCalendar && isSyncingWithNylas && (
            <Text h='400' color='gray-500'>
              Syncing calendar…
            </Text>
          )}

          {calendar && <CheckmarkInCircleSVG className='w-4 h-4 text-green-600' />}
          {calendar && (
            <Text h='400' color='gray-500' truncate>
              {calendar?.name}
            </Text>
          )}
        </div>
      </div>
      {hasDropdown && (
        <button className='group-hover:block hidden' name='more'>
          <CaretDownSVG />
        </button>
      )}
      {loading && <Spinner className='w-4 h-4' />}
      <PopUp open={open} zIndex={30} className='top-full left-0 right-0 px-6' whitespace='normal'>
        <Card className='max-h-48 overflow-y-scroll shadow-lg' noPadding>
          {calendars && (
            <div className='p-4'>
              <Text h='400' bold className='mb-1'>
                {user.name}’s calendars ({calendars.length})
              </Text>
            </div>
          )}
          {calendars
            ?.filter((cal) => !cal.read_only)
            .map((cal) => (
              <DropdownLink
                disabled={cal.read_only || disabled}
                key={cal.id}
                onClick={() => handleClickCalendarId(cal)}
              >
                {cal.name}
              </DropdownLink>
            ))}
          {!calendars && requireCalendar && isSelf && (
            <div className='p-4'>
              <Text h='400' color='gray-500' mb='4'>
                Please re-connect your calendar to prevent scheduling issues.
              </Text>
              <AuthButton small primary provider='nylas'>
                Re-connect
              </AuthButton>
            </div>
          )}
          {!calendar && requireCalendar && !isSelf && (
            <div className='p-4'>
              <Text h='400' color='gray-500'>
                There's an issue with this user's calendar connection. Please remind them to resolve the issue in order
                to prevent scheduling issues.
              </Text>
            </div>
          )}
          <hr />
          {requireCalendar && onClickSwitchToCalendly && (
            <DropdownLink onClick={onClickSwitchToCalendly}>Switch to Calendly…</DropdownLink>
          )}

          <TippyOrNot
            content={`Please assign another study owner before removing ${user.name} from the moderator rotation`}
            show={isOwner}
          >
            <DropdownLink disabled={isOwner || disabled} onClick={handleClickRemove}>
              Remove as {type}
            </DropdownLink>
          </TippyOrNot>
        </Card>
      </PopUp>
    </div>
  );
};
