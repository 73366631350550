import pluralize from 'pluralize';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { api } from '@api/reduxApi';
import { AvatarFromId, Button, Modal, Text } from '@components/common';
import { UserSelect } from '@components/shared/UserSelect';
import { compact } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { useToaster } from '@stores/toaster';

import { failedChangeModerator } from '../../../toasts';
import { FilterQuery } from '@components/CandidatesApp/CandidatesIndex/types';
import { useFeature } from '@hooks/useFeature';

type Props = {
  study: Study;
  participationIds: number[];
  onClose: () => void;
  onSuccess: (backgroundTask: BackgroundTask) => void;
  allSelected?: boolean;
  query?: FilterQuery;
};

export const UpdateModeratorModal: React.FC<Props> = ({
  allSelected,
  query,
  study,
  participationIds,
  onClose,
  onSuccess
}) => {
  const serversideParticipantsEnabled = useFeature('serverside_participations');

  const {
    account: { team }
  } = useAccount();

  const showToast = useToaster();

  const [userId, setUserId] = useState<number>();
  const user = team.find((u) => u.id === userId);

  const [update, { data, isLoading, isSuccess, isError, error }] = api.useBulkParticipationModeratorMutation();

  const handleClick = () => {
    if (userId)
      update({
        studyId: study.id,
        ...(allSelected && serversideParticipantsEnabled ? { query } : { participationIds }),
        userId
      });
  };

  useEffect(() => {
    if (isSuccess && data) onSuccess(data as BackgroundTask);
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError) {
      const errors: string[] = error?.['data']?.['errors'] || [];
      showToast(failedChangeModerator(errors));
    }
  }, [isError]);

  const needsZoom = study.video_url_type === 'zoom';
  const needsNylas = study.is_nylas;
  const thingsNeeded = compact([needsZoom && 'Zoom account', needsNylas && 'calendar']).join(' and ');
  const thingsBeingRemoved = compact([needsZoom && 'Zoom meetings', needsNylas && 'calendar events']).join(' and ');

  return (
    <Modal size='md' title='Change moderator' onClose={onClose}>
      {thingsBeingRemoved && <Text mb='2'>It may take a few minutes to move any {thingsBeingRemoved}.</Text>}
      {!userId && (
        <div className='pb-6'>
          <Text mb='2'>Select moderator for {pluralize('interviews', participationIds.length, true)}:</Text>
          <div className='mb-2'>
            <UserSelect users={team} onSelect={setUserId} />
          </div>
          <Text color='gray-500' h='400'>
            Only non-observers {thingsNeeded && <>with {thingsNeeded} connected </>}can be made moderator.
          </Text>
        </div>
      )}
      {user && (
        <div>
          <Text mb='6'>We’ll reassign this interview to:</Text>
          <div className='flex items-center'>
            <AvatarFromId className='mr-2' userId={userId} />
            <Text h='400'>{user.name}</Text>
          </div>
        </div>
      )}
      <div className='flex justify-end space-x-2'>
        <Button onClick={onClose}>Cancel</Button>
        <Button loading={isLoading} disabled={isLoading || !userId} primary onClick={handleClick}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};
