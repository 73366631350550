import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { EditorOptions } from '@tiptap/core';
import { Editor } from '@tiptap/core';

import { useUpdateDocumentMutation } from '../api';
import { SAVE_DEBOUNCE } from '../constants';
import { useHighlightSync } from './useHighlightSync';

interface UseEditorUpdateArgs {
  autoSave?: boolean;
  editor: Editor | null;
  documentId?: number;
  readonly?: boolean;
  onUpdate?: (args: { editor: Editor }) => void;
  onSave?: (args: { editor: Editor }) => void;
}

export type UseEditorUpdateType = (
  args: UseEditorUpdateArgs
) => [(documentId?: number) => Promise<void>, { isLoading: boolean }];

export const useEditorUpdate: UseEditorUpdateType = ({ autoSave, editor, documentId, readonly, onSave, onUpdate }) => {
  const [updateDocument, { isLoading }] = useUpdateDocumentMutation();
  const { capture, sync } = useHighlightSync({ documentId });

  const save = async (id?: number) => {
    const documentIdToSave = id || documentId;

    if (!documentIdToSave || !editor) return;

    await updateDocument({ id: documentIdToSave, doc: editor.getJSON() }).unwrap();

    sync(editor);

    onSave?.({ editor });
  };

  const { callback: debouceSave } = useDebouncedCallback(save, SAVE_DEBOUNCE);

  const onUpdateHandler: EditorOptions['onUpdate'] = async (params) => {
    const { editor, transaction } = params;

    if (readonly || !editor) return;

    onUpdate?.({ editor });

    if (!autoSave) return;

    const isImmidiateUpdate = transaction.getMeta('immidiateUpdate');

    if (isImmidiateUpdate) {
      await save();
    } else {
      await debouceSave();
    }
  };

  useEffect(() => {
    if (editor && !editor.isDestroyed) {
      editor.on('update', onUpdateHandler);
      editor.on('transaction', capture);
    }

    return () => {
      if (editor && !editor.isDestroyed) {
        editor.off('update', onUpdateHandler);
        editor.off('transaction', capture);
      }
    };
  }, [editor, documentId]);

  return [save, { isLoading }];
};
