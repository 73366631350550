import * as React from 'react';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import tinytime from 'tinytime';

import { api } from '@api/reduxApi';
import { Alert, Button, Input, Modal, Text } from '@components/common';
import { useToaster } from '@stores/toaster';

import { failedUpdate, successUpdate } from '../../../toasts';
import { FocusGroupSessionInput } from '../../FocusGroupSessionInput';
import { FilterQuery } from '@components/CandidatesApp/CandidatesIndex/types';

const template = tinytime('{MMMM} {DD} at {h}:{mm}{a}');

type Props = {
  study: Study;
  participation: Participation;
  onClose: () => void;
  onSuccess: () => void;
};

export const timeIsEarlier = (time: number | Date) => +time < +new Date();
export const timeIsSame = (time: number | Date, interviewAt?: Date | null) =>
  Math.floor(+time / 1000) === Math.floor(+(interviewAt || 0) / 1000);

export const ManualRescheduleModal: React.FC<Props> = ({ study, participation, onClose, onSuccess }) => {
  const showToast = useToaster();

  const [time, setTime] = useState(() => {
    if (study.focus_group) {
      return participation.interview_at || new Date();
    } else {
      const oneDay = 24 * 60 * 60 * 1000;
      const interviewAt = oneDay + (participation.interview_at ? +new Date(participation.interview_at) : 0);
      const now = +new Date();
      return interviewAt > now ? interviewAt : now;
    }
  });

  const [update, { data, isLoading, isSuccess, isError, error }] = api.useUpdateParticipationMutation();

  const handleClick = () => {
    update({ id: participation.id, interview_at: new Date(time) });
  };

  useEffect(() => {
    if (isSuccess && data) {
      showToast(successUpdate());
      onSuccess?.();
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError) {
      showToast(failedUpdate());
    }
  }, [isError]);

  const isEarlier = timeIsEarlier(time);
  const isSame = timeIsSame(time, participation.interview_at);
  const isValidTime = !isEarlier && !isSame;

  return (
    <Modal size='md' title='Choose new time' onClose={onClose}>
      <div className='mb-6'>
        <Text>
          {participation.interview_at &&
            `The interview with ${participation.name} is currently scheduled for ${template.render(
              participation.interview_at
            )}. `}
          {!participation.interview_at &&
            `There’s currently no time set for the interview with ${participation.name}. `}
          Set a new time and we’ll update the calendar details & notify the participant.
        </Text>
      </div>
      <div className='mb-10'>
        {study.focus_group ? (
          <>
            <Text mb='1' bold>
              New Session
            </Text>
            <FocusGroupSessionInput
              studyId={study.id}
              interviewAt={time.toString()}
              setInterviewAt={(date) => setTime(new Date(date))}
            />
          </>
        ) : (
          <>
            <Text mb='1' bold>
              New Time
            </Text>
            <DatePicker
              wrapperClassName='w-20'
              customInput={<Input size='full' />}
              selected={time ? new Date(time) : null}
              onChange={(date) => (date ? setTime(+date) : null)}
              placeholderText='Choose a date & time…'
              showTimeSelect
              minDate={new Date()}
              className='w-full text-left border border-gray-300 rounded-md'
              dateFormat='Pp'
            />
          </>
        )}
        {isEarlier && (
          <Alert className='mt-4' type='warning'>
            Calendar events can’t be booked in the past.
          </Alert>
        )}
        {isSame && (
          <Alert className='mt-4' type='warning'>
            This the same as the currently scheduled time.
          </Alert>
        )}
      </div>
      <div className='flex justify-end space-x-2'>
        <Button onClick={onClose}>Cancel</Button>
        <Button loading={isLoading} disabled={isLoading || !isValidTime} primary onClick={handleClick}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};
