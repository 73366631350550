import * as React from 'react';
import { useEffect, useState } from 'react';

import { api as reduxApi, api } from '@api/reduxApi';
import { Alert, Loading, SlideOut } from '@components/common';
import { useCancelBackgroundTaskMutation } from '@components/shared/BackgroundTaskStatus/api';
import { useFeature } from '@hooks/useFeature';
import { usePlan } from '@hooks/usePlan';
import { useToasterWithTimeout } from '@stores/toaster';

import { AlreadyParticipatedAlert, UncontactableAlert } from './components/alerts';
import { MAX_BATCH_SIZE } from './consts';
import { StudyMessageSlideOut } from './StudyMessageSlideOut';
import { canceledSend, failedSend, successSend } from './toasts';
import { SendMessageParams } from './types';
import { getBatchingSettings } from './utils/getBatchingSettings';
import { useStudyLimits } from 'components/StudiesApp/components/StudyPublished/hooks/useStudyLimits';
import { ServerFilterQuery } from 'components/CandidatesApp/CandidatesIndex/types';
import { useParticipantsCounts } from '@components/StudyMessages/hooks/useParticipantsCounts';

interface Props {
  teamId?: number | null;
  study: Study;
  participationsIds: number[];
  resend?: boolean;
  onClose: () => void;
  onSuccess: (backgroundTask: BackgroundTask) => void;
  allSelected?: boolean;
  query?: ServerFilterQuery;
}

export const ParticipationsInviteSlideOut: React.FC<Props> = ({
  study,
  participationsIds,
  resend = false,
  onClose,
  onSuccess,
  allSelected,
  query
}) => {
  const serversideParticipantsEnabled = useFeature('serverside_participations');

  const {
    ineligibleCount: ineligible,
    invitableCount: invitable,
    contactableCount: contactable
  } = useParticipantsCounts({
    studyId: study.id,
    query
  });

  const communicationMethod = study?.comms_medium || 'email';
  const [getData, { data, isSuccess, isLoading }] = api.useGetInviteSlideOutIdsMutation();
  const [cancelBackgroundTask] = useCancelBackgroundTaskMutation();
  const { hasQuota, getQuota } = usePlan();
  const [showToast, clearToastTimeout] = useToasterWithTimeout();
  const [invite, { isLoading: isSending }] = api.useBulkParticipationInviteMutation();
  const { studyLimits, studyLimitMatches } = useStudyLimits(study, {
    ...(allSelected && serversideParticipantsEnabled ? { query } : { ids: data?.inviteable_ids || [] })
  });

  const handleSend: (params: SendMessageParams) => Promise<void> = async ({
    batch,
    addToBatch,
    resend,
    sender,
    excludeIneligible,
    message
  }) => {
    const sendNow = study?.batch_on && !addToBatch;

    if (!data) return;

    const invite_params = {
      batch_on: batch.on,
      batch_size: batch.size,
      batch_wait_hours: batch.wait_hours,
      batch_auto_restart: batch.auto_restart
    };

    // FIXME: Move to use participation ids.
    try {
      const backgroundTask = await invite({
        studyId: study.id,
        sender: sender,
        send_now: sendNow,
        invite: invite_params,
        exclude_ineligible: excludeIneligible,
        message_id: message && message.custom ? message.id : null,
        template_name: 'invite',
        ...(allSelected && serversideParticipantsEnabled
          ? { query }
          : { customer_ids: data.inviteable_ids.concat(resend ? data.reinviteable_ids : []) })
      }).unwrap();

      showToast(successSend(!!study, study, backgroundTask, handleCancel));
      onSuccess(backgroundTask);
    } catch (e) {
      showToast(failedSend(e.data.error));
    }
  };
  const handleCancel: (task: BackgroundTask) => Promise<void> = async (task) => {
    clearToastTimeout();

    try {
      await cancelBackgroundTask(task).unwrap();
      showToast(canceledSend());
    } catch (e) {
      showToast(failedSend(e.data.error));
    }
  };

  const count = serversideParticipantsEnabled ? invitable : isSuccess && data ? data.inviteable_ids.length : 0;
  const resendCount = isSuccess && data ? data.reinviteable_ids.length : 0;
  const contactableCount = serversideParticipantsEnabled
    ? contactable
    : isSuccess && data
    ? data.contactable_ids.length
    : 0;
  const ineligibleCount = serversideParticipantsEnabled
    ? ineligible
    : isSuccess && data
    ? data.ineligible_ids.length
    : 0;
  const possibleCount = count + resendCount;
  const participatingCount = contactableCount - possibleCount;

  const batchingSettings = getBatchingSettings(count, study);
  const hasInviteQuota = hasQuota('invites', count);

  // Fetch relevant ids on load.
  useEffect(() => {
    getData({
      participationsIds: participationsIds,
      communicationMethod: communicationMethod
    });
  }, []);

  if (!hasInviteQuota) {
    return (
      <SlideOut title='Participation invite' onClose={onClose} size='xl'>
        <div className='p-6'>
          <Alert type='error'>
            Cannot send all {count} messages as this exceeds your remaining quota of {getQuota('invites')}
          </Alert>
        </div>
      </SlideOut>
    );
  }

  if (isLoading || !data) {
    return (
      <SlideOut title='Participation invite' onClose={onClose} size='xl'>
        <div className='p-6'>
          <Loading absolute />
        </div>
      </SlideOut>
    );
  }

  if (possibleCount === 0) {
    return (
      <SlideOut title='Participation invite' onClose={onClose} size='xl'>
        <div className='p-6'>
          <Alert type='error'>
            There are no valid candidates to invite. Please check the selected candidates and try again.
          </Alert>
        </div>
      </SlideOut>
    );
  }

  return (
    <StudyMessageSlideOut
      continuousable={true}
      batchable
      batchingRequired={count > MAX_BATCH_SIZE}
      defaultBatching={batchingSettings}
      title='Participation invite'
      batchingTitle='study invites'
      study={study}
      event={resend ? 'invited_reminder' : 'invite'}
      inviteableCount={count}
      ineligibleCount={ineligibleCount}
      customizable={!study.batch_on || !study.next_batch_at}
      resendCount={resendCount}
      defaultResend={resend}
      previewId={data?.contactable_ids?.[0]}
      onClose={onClose}
      onSend={handleSend}
      studyLimits={studyLimits}
      studyLimitMatches={studyLimitMatches}
    >
      <>
        {isSending && <Loading absolute />}
        {participatingCount > 0 && (
          <AlreadyParticipatedAlert diff={participatingCount} total={participationsIds.length} />
        )}
        {participationsIds && contactableCount < participationsIds.length && (
          <UncontactableAlert
            diff={participationsIds.length - contactableCount}
            medium={study.comms_medium}
            total={participationsIds.length}
          />
        )}
      </>
    </StudyMessageSlideOut>
  );
};
