import { api as reduxApi } from '@api/reduxApi';
import { ServerFilterQuery } from '@components/CandidatesApp/CandidatesIndex/types';
import { useFeature } from 'hooks/useFeature';

type UseParticipantsCounts = {
  invitableCount: number;
  ineligibleCount: number;
  contactableCount: number;
};

interface Props {
  studyId: number;
  query?: ServerFilterQuery;
}

export const useParticipantsCounts = ({ studyId, query }: Props): UseParticipantsCounts => {
  const serversideParticipantsEnabled = useFeature('serverside_participations');

  const { data: invitable } = reduxApi.useGetParticipationsPagedQuery(
    {
      study_id: studyId,
      fields: ['id'],
      page: 1,
      query: {
        filters: [...(query?.filters || []), 'customer:eligibility is true'],
        searchQuery: query?.q
      },
      items: 1
    },
    { skip: !serversideParticipantsEnabled }
  );

  const { data: ineligible } = reduxApi.useGetParticipationsPagedQuery(
    {
      study_id: studyId,
      fields: ['id'],
      page: 1,
      query: {
        filters: [...(query?.filters || []), 'customer:eligibility is false'],
        searchQuery: query?.q
      },
      items: 1
    },
    { skip: !serversideParticipantsEnabled }
  );

  const { data: contactable } = reduxApi.useGetParticipationsPagedQuery(
    {
      study_id: studyId,
      fields: ['id'],
      page: 1,
      query: {
        filters: [...(query?.filters || []), 'customer:contactable is true'],
        searchQuery: query?.q
      },
      items: 1
    },
    { skip: !serversideParticipantsEnabled }
  );

  console.log('invitableCounts', invitable, 'ineligibleCounts', ineligible, 'contactable', contactable);

  return {
    invitableCount: invitable?.meta.count || 0,
    ineligibleCount: ineligible?.meta.count || 0,
    contactableCount: contactable?.meta.count || 0
  };
};
