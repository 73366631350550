import type { ArtifactHit } from '@components/RepositoryApp/types';

import { uid } from '@components/utils';

type Builder<T> = (props?: Partial<T>) => T;

export const buildUser: Builder<User> = (props = {}) => ({
  id: 3,
  name: 'Claire Soso',
  first_name: 'Claire',
  initials: 'CS',
  deactivated: false,
  email: 'Claire@example.com',
  job_title: 'Researcher',
  default_booking_url: null,
  default_video_url: null,
  default_nylas_calendar_id: null,
  nylas_calendars: [],
  event_types: [],
  nylas: false,
  calendly: false,
  zoom: false,
  nylas_loaded: false,
  zoom_invalid: false,
  nylas_invalid: false,
  valid_email_integration: true,
  is_phantom: false,
  created_at: new Date(),
  ai_enabled: true,
  consent_form_id: null,
  ...props
});

export const buildTeamUser: Builder<TeamUser> = (props = {}) => {
  return {
    ...buildUser(props || {}),
    role: props.role || 'creator',
    account_user_id: props.account_user_id
  };
};

export const buildTeam: Builder<Team> = (props = {}) => ({
  id: 1,
  name: 'Team',
  initial: 'T',
  users: [],
  icon: null,
  color: null,
  allow_join: true,
  brand: null,
  wallet_available: 0,
  wallet_allocated: 0,
  wallet_spent: 0,
  ...props
});

export const buildPlan: Builder<WhoamiPlan> = (props = {}) => ({
  id: 1,
  name: '',
  key: 'starter',
  interval: 'month',
  costs: {} as any,
  features: {} as any,
  limits: {} as any,
  quotas: {} as any,
  ...props
});

export const buildStudyMessage: Builder<StudyMessage> = (props = {}) => ({
  id: 2,
  event: 'invite',
  subject: 'Sign up!',
  medium: 'email',
  layout: 'default',
  default_sender: null,
  default_sender_account: null,
  text: null,
  cta_required: true,
  has_subject: true,
  document_id: 3,
  ...props
});

export const buildEmailSender: Builder<EmailSender> = (props = {}) => ({
  id: 1,
  user_id: 3,
  email: 'Claire@example.com',
  name: 'Claire Soso',
  disabled: false,
  connected: true,
  delivery_method: 'vero',
  level: 'system',
  ...props
});

export const buildStudyUser: Builder<StudyUser> = (props = {}) => ({
  user_id: 1,
  study_id: 1,
  role: 'observer',
  nylas_calendar_id: null,
  ...props
});

export const buildAccount: Builder<GQAccount> = (props = {}) => ({
  id: 123,
  sandbox: false,
  created_at: new Date().toString(),
  unique_id_attr: 'email',
  name: 'Cool Co.',
  ai_enabled: true,
  ai_access: true,
  trial_ends_at: null,
  workspace_name: 'Cool team',
  default_email_layout: 'default',
  consent_placement: 'apply',
  status: 'active',
  workspace: 'cool-co',
  card_type: 'Visa',
  card_last4: '1234',
  auth_methods: ['password', 'google', 'saml'],
  delete_data_after_six_months: false,
  cards: [
    {
      kind: 'card',
      processor_id: 'pm_13',
      brand: 'Visa',
      last4: '1234',
      is_default: true
    }
  ],
  logo: '-',
  has_slack: false,
  team: [],
  domain: '',
  owner: { name: '', id: 1, email: '123@12.com' },
  permissions: { view_pii: 'creator', manage_attrs: 'creator' } as any,
  interview_room_access: 'public',
  highlight_reel_access: 'public',
  clip_access: 'public',
  insight_access: 'public',
  hide_global_opt_out_candidate_ux: false,
  wallet: {
    available: 0,
    allocated: 0,
    spent: 0,
    average: 0,
    outstanding: 0
  } as Wallet,
  team_wallets_enabled: false,
  external_incentives: [],
  external_credits: [],
  ...props
});

export const buildWhoami: Builder<Whoami> = (props = {}) => ({
  isMasquerading: false,
  account: buildAccount(props.account || {}),
  plan: buildPlan(props.plan || {}) as any,
  role: props.role || 'creator',
  user: buildUser(props.user || {}),
  account_public_domain: {
    base_url: 'https://greatquestion.co'
  },
  config: {
    algolia: {
      index_suffix: '_test',
      app_id: '2',
      api_key: '3'
    },
    features: {
      paginated_studies: true,
      troubleshoot_ui: false,
      account_switcher: true,
      party_segments: true,
      dev_error_page: true,
      algolia: false,
      consent_placement: true,
      screener_invite_cta: true,
      salesforce_auth: true,
      unmoderated: true,
      focus_groups: true,
      teams: true,
      api_access: true,
      mfa: true,
      custom_opt_out_salesforce: true,
      team_wallets: true,
      unique_custom_attrs: true,
      import_document: true,
      participations_polling: true,
      allow_skip_screener_submit: true,
      participant_level_incentives: true,
      non_video_highlights: true,
      new_sender_select: true,
      call_url_alerts: true,
      new_csv_import: true,
      serverside_participations: false,
      unmoderated_card_sort: true
    },
    customAttrs: [],
    hide_dashboard_stats: false
  }
});

export const buildCandidate: Builder<Candidate> = (props = {}) => ({
  id: 4,
  first_name: 'Bella',
  name: 'Bella Smith',
  initials: 'BS',
  phone_number: '+1 415 555 5555',
  email: 'bella@example.com',
  extra: {},
  created_at: +new Date(),
  updated_at: +new Date(),
  deleted_at: null,
  unsubscribed: null,
  unsubscribed_at: null,
  opted_in: true,
  consented_at: +new Date(),
  timezone: null,
  contact_access: 'public',
  average_rating: null,
  ...props
});

export const buildParticipation: Builder<Participation> = (props = {}) => ({
  id: 5,
  name: 'Party Person',
  first_name: 'Party',
  initials: 'PP',
  deleted_at: null,
  email: 'party@example.com',
  phone_number: null,
  opted_in: true,
  project_id: 5,
  customer_id: 1,
  document_id: null,
  created_at: +new Date(),
  updated_at: +new Date(),
  consented_at: +new Date(),
  interview_at: null,
  duration: 20,
  applied_at: null,
  invited_at: null,
  thanked_at: null,
  reschedule_requested_at: null,
  last_activity_at: new Date(),
  recording_id: null,
  moderator_id: 2,
  in_request_batch: false,
  join_url: null,
  status: 'shortlisted',
  external_status: null,
  style: 'video_call',
  token: '@$R#F#',
  external: false,
  recruiting_source: 'Candidates',
  ideal_pre_screener_response: false,
  pre_screener_response_match_score: 2,
  consent: null,
  consent_token: '12211122',
  public_path: '',
  study_path: '',
  interview_path: '',
  action_required: '',
  incentive_in_cents: 200,
  incentive_in_whole_currency: 2,
  event_details: null,
  tags: [],
  zoom_meeting: null,
  live_stream_status: '',
  time_proposal: null,
  waitlisted_at: null,
  rsvp: 'missing',
  ...props
});

export const buildStudy: Builder<Study> = (props = {}) => ({
  id: 2,
  owner_id: 4,
  owner: buildUser({
    first_name: 'Erin',
    name: 'Erin Smooth',
    email: 'erin@smooth.com'
  }),
  title: 'Study',
  research_goal: '',
  screener_invite_cta: 'form',
  slug: 'study',
  created_at: new Date(),
  updated_at: new Date(),
  deleted_at: null,
  creator_id: 2,
  on_gq: true,
  state: 'active',
  style: 'video_call',
  has_screener: false,
  visible_attrs: [],
  participant_limit: 5,
  duration_in_minutes: 30,
  incentive: 10,
  incentive_method: 'manual',
  has_incentive: true,
  currency: 'USD',
  currencySymbol: '$',
  batch_on: false,
  video_url_type: 'manual',
  time_slot_settings: {
    days: [],
    start_time: new Date().toISOString(),
    end_time: new Date().toISOString(),
    future_limit: 0,
    timezone: 'UTC',
    call_buffer: 0
  },
  cal_available_slots: {
    slots: [],
    start_date: new Date().toISOString(),
    end_date: new Date().toISOString()
  },
  cal_view_settings: {
    start_time: new Date().toISOString(),
    start_date: +new Date(),
    end_time: new Date().toISOString(),
    week_days_only: true
  },
  label: 'Interviews',
  tags: [],
  user_ids: [2],
  moderated: true,
  maximum_slots: 8,
  nylas_calendar_id: null,
  nylas_calendar_type: 'user',
  comms_medium: 'email',
  external_platform_settings: {},
  screener_review: 'manual',
  time_proposals_enabled: false,
  waitlist_enabled: false,
  pre_screener: null,
  survey_screener: null,
  landing_page: null,
  recurring_participant_limit: null,
  conflict_scope: 'global',
  session_capacity: null,
  incentive_title: null,
  incentive_instructions: null,
  incentive_coupons: null,
  pending_reasons: [],
  batch_size: null,
  batch_wait_hours: null,
  next_batch_at: null,
  start_url: null,
  booking_url: null,
  video_url: null,
  location: {},
  first_time_slot_at: null,
  device_type: 'desktop',
  slots: {
    total: 5,
    funded: 0,
    available: 5,
    remaining: 5,
    open: 5,
    target: 5
  },
  stats: {
    sent: 0,
    opened: 0,
    converted: 0,
    clicked: 0
  },
  funding: {
    local_total: 0,
    local_unallocated: 0,
    local_issued: 0,
    local_unissued: 0,
    usd_outstanding: 0,
    usd_refundable: 0,
    usd_total: 0,
    usd_unissued: 0,
    recruitment_fees: 0,
    wallet_balance: 0,
    is_card_refundable: false,
    uses_team_wallet: false
  },
  external_candidates_enabled: false,
  external_fee_per_b2b_participant_in_cents: 4000,
  external_fee_per_b2c_participant_in_cents: 2400,
  external_candidates_requests_count: 0,
  external_url: null,
  potentially_active_external_candidates_requests_count: 0,
  currently_active_external_candidates_requests_count: 0,
  has_external_candidates_requests: false,
  has_requested_any_participants: false,
  calendly_event_type_uuid: null,
  style_label: 'Video Call',
  language: 'en',
  schedulable_beginning: new Date().toISOString(),
  schedulable_end: new Date().toISOString(),
  workspace: '',
  live_stream_enabled: true,
  maximum_as_started_in_minutes: 15,
  ...props
});

export const buildScreener = (props?: Partial<Screener>): Screener => ({
  id: 1,
  name: 'screener name',
  project_id: 5,
  responses_count: 0,
  gq_form: true,
  style: 'video_call',
  on_gq: true,
  company_name: 'account',
  slug: 'slug',
  screener_type: 'pre',
  fields: [buildScreenerField()],
  has_ideal_answers: false,
  ...props
});

export const buildScreenerField = (props?: Partial<ScreenerField>): ScreenerField => ({
  id: 1,
  helper: '',
  label: '',
  required: false,
  field_type: 'short_text',
  position: 1,
  skip_logic: null,
  meta: {},
  next_screener_field_id: null,
  external_field_id: null,
  ...props
});

export const buildScreenerResponse = (props?: Partial<ScreenerResponse>): ScreenerResponseResultsIndividual => ({
  id: uid(),
  answers_json: [
    {
      field_id: uid(),
      field_type: 'short_text' as const,
      label: 'Label short',
      value: 'Short text answer',
      ideal: false,
      submitted_at: '10.22.1990',
      blockable_id: null,
      blockable_type: null,
      timings: null
    },
    {
      field_id: uid(),
      field_type: 'long_text' as const,
      label: 'Label long',
      value: 'Long text answer',
      ideal: false,
      submitted_at: '10.22.1990',
      blockable_id: null,
      blockable_type: null,
      timings: null
    }
  ] as ScreenerResponseAnswer[],
  hidden_answers: {},
  customer_id: uid(),
  participation: buildParticipation() as any,
  ...props
});

export const buildRecording = (props?: Partial<Recording>): Recording => ({
  id: 1,
  token: '',
  created_at: new Date(),
  updated_at: new Date(),
  creator_id: 1,
  document_id: 1,
  title: '',
  filename: '',
  byte_size: 0,
  transcript: null,
  error_code: null,
  clips: [],
  ...props
});

export const buildClip = (props?: Partial<Clip>): Clip => ({
  id: 1,
  title: '',
  description: '',
  from: 0,
  to: 1,
  recording_id: 1,
  token: '',
  highlight_id: null,
  highlight: null,
  session_uuid: '',
  stream_url: '',
  views: [],
  tag_ids: [],
  created_at: '',
  access: 'private',
  ...props
});

export const buildHighlight = (props?: Partial<Highlight>): Highlight => ({
  id: 1,
  text: '',
  ai: false,
  color: 'default',
  creator_id: 1,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  document_id: 1,
  document_type: 'Story',
  document_title: '',
  document_source_url: '',
  tag_ids: [],
  uuid: '',
  views: [],
  access: 'private',
  ...props
});

export const buildInsight = (props?: Partial<Insight>): Insight => ({
  id: 1,
  title: '',
  text: '',
  slug: '',
  created_at: new Date(),
  updated_at: new Date(),
  creator_id: 1,
  document_id: 1,
  user_ids: [],
  sample: false,
  tags: [],
  tag_ids: [],
  access: 'private',
  scrub_pii: false,
  views: [],
  ...props
});

export const buildGuide = (props?: Partial<Guide>): Guide => ({
  id: 1,
  account_id: 2,
  created_at: new Date('2023-08-13T18:12:26.044+03:00'),
  updated_at: new Date('2023-11-21T19:42:18.075+02:00'),
  published_at: new Date('2023-08-13T18:12:58.930+03:00'),
  title: 'guide title',
  bg_color: 'red',
  token: '601238',
  document_id: 9,
  published: true,
  word_count: 900,
  creator: buildUser() as Guide['creator'],
  account: {
    logo_url: 'www.avatar.com',
    name: 'acc name',
    id: 1
  },
  ...props
});

export const buildZoomMeeting = (props?: Partial<ZoomMeeting>): ZoomMeeting => ({
  user_id: 1,
  meeting_id: 123456,
  uuid: 'abcdef',
  join_url: 'zoom.us/j/800123456',
  start_time: new Date().toISOString(),
  duration: 30
});

export const buildRepoSession = (props?: Partial<RepoSession>): RepoSession => ({
  uuid: uid().toString(),
  views_count: 0,
  views: [],
  session_at: new Date(),
  title: 'RepoSession',
  chapters: { chapters: [{ raw_text: '00:00: Chapter 1', title: 'Chapter 1', timestamp: 0, time_str: '00:00' }] },
  summary: 'RepoSession summary',
  tags: [],
  tag_ids: [],
  access: 'private',
  scrub_pii: true,
  document_id: null,
  kind: 'interview',
  study: null,
  candidate: null,
  candidate_id: null,
  recording_id: null,
  project_id: null,
  participation: null,
  recording: null,
  creator: buildUser(),
  ...props
});

export const buildTemplate = (props?: Partial<InterviewTemplate>): InterviewTemplate => ({
  id: 1,
  illustration_url: null,
  created_at: new Date(),
  updated_at: new Date(),
  account_id: 2,
  account: {
    id: 1,
    name: '',
    logo_url: ''
  },
  default: false,
  title: 'New template 1',
  document_id: 1,
  creator: {
    id: 1,
    name: '',
    initials: '',
    img: ''
  },
  ...props
});

export const buildRecordingItem = (params?: Partial<RecordingItem>): RecordingItem => ({
  id: uid(),
  type: 'recording',
  session: buildRepoSession(),
  file: {} as any,
  status: 'queued',
  progress: 0,
  tagIds: [],
  ...params
});

export const buildZoomItem = (params?: Partial<ZoomItem>): ZoomItem => ({
  id: uid(),
  type: 'zoom',
  session: buildRepoSession(),
  cloudRecording: {
    uuid: String(uid()),
    id: 812345678,
    start_time: new Date(),
    duration: 3000,
    topic: 'Interview'
  },
  status: 'queued',
  tagIds: [],
  ...params
});

export const buildMuxVideo = (params?: Partial<MuxVideo>): MuxVideo => ({
  id: uid(),
  playback_id: '',
  asset_id: '',
  download_ready: false,
  audio_only: false,
  status: 'preparing',
  thumbnail_url: null,
  animated_thumbnail_url: null,
  ...params
});

export const buildStudyLimit = (params?: Partial<StudyLimit>): StudyLimit => ({
  id: 1,
  order: 1,
  value: 0,
  name: '',
  filters: { op: 'any', filters: [] },
  ...params
});

export const buildPublicActivity = (params?: Partial<PublicActivity>): PublicActivity => ({
  id: 1,
  key: 'public_activity.test_key',
  customer: {
    id: 2,
    first_name: 'Customer',
    name: 'Customer User'
  },
  owner: {
    type: 'User',
    id: 3,
    name: 'Owner User',
    initials: 'OU',
    img: ''
  },
  created_at: new Date(),
  project: {},
  parameters: {},
  ...params
});

export const buildBrand = (params: Partial<Brand>): Brand => ({
  logo: null,
  id: 1,
  button_color: '#D34B4B',
  text_color: '#252F3F',
  logo_alignment: 'left',
  secondary_text_color: '#374151',
  button_text_color: '#F2F2F2',
  ...params
});

export const buildTag = (params: Partial<Tag>): Tag => ({
  id: uid(),
  ai: false,
  name: 'Tag',
  color: 'default',
  taggings: [],
  tag_group_id: null,
  taggings_count: 0,
  project_id: null,
  ...params
});

export const buildArtifactHit = (params?: Partial<ArtifactHit>): ArtifactHit => ({
  objectID: 'Repo::Session_1',
  account_id: 1,
  owner_name: '',
  owner_id: 2,
  title: '',
  subtitle: '',
  body: '',
  kind: 'Repo::Session',
  model_id: 1,
  created_at: '',
  updated_at: '',
  study_ids: [],
  study_user_ids: [],
  href: '',
  tag_ids: [],
  candidate_name: '',
  study_title: null,
  ...params
});

export const buildEmailTemplate = (params?: Partial<EmailTemplate>): EmailTemplate => ({
  id: 20,
  subject: 'subject',
  event: 'invite' as const,
  title: 'title',
  default: false,
  global: false,
  account: {} as any,
  account_id: 2,
  document_id: 219,
  created_at: new Date('2023-11-21T16:22:58.403+02:00'),
  updated_at: new Date('2023-11-21T16:23:00.493+02:00'),
  illustration_url: null,
  creator: {
    id: 2,
    name: 'Igor Shtyka',
    initials: 'IS',
    img: 'http://url.com/'
  } as any,
  ...params
});

export const buildInterviewTemplate = (params?: Partial<InterviewTemplate>): InterviewTemplate => ({
  id: 22,
  title: 'interview template title',
  default: false,
  global: false,
  account_id: 2,
  document_id: 225,
  created_at: new Date('2023-11-27T18:38:47.685+02:00'),
  updated_at: new Date('2023-11-27T18:38:47.685+02:00'),
  illustration_url: null,
  account: { id: 1, name: '', logo_url: 'http://url.com/' },
  creator: {
    id: 2,
    name: 'Igor Shtyka',
    initials: 'IS',
    img: 'http://url.com/'
  },
  ...params
});

export const buildIncentive = (params?: Partial<Incentive>): Incentive => ({
  id: 1,
  local_amount_in_cents: 5000,
  redeemed_at: null,
  sent_at: null,
  resent_at: null,
  used_at: null,
  created_at: new Date('2023-11-27T18:38:47.685+02:00'),
  updated_at: new Date('2023-11-27T18:38:47.685+02:00'),
  currency: 'USD',
  status: 'pending',
  candidate: {
    id: 1,
    name: 'Ivan Drago',
    email: 'ivan_drago@gmail.com',
    deleted_at: null
  },
  study: {
    id: 23,
    title: 'study title',
    state: 'active',
    owner_id: 5,
    deleted_at: null
  },
  participation: {
    id: 2,
    status: 'invited',
    deleted_at: null
  },
  ...params
});

export const buildAiChat = (params?: Partial<AiChat>): AiChat => ({
  created_at: new Date('2021-01-01T12:00:00.000Z').toString(),
  prompt_id: 'study',
  uuid: String(uid()),
  user_id: 1,
  context: { context_type: 'study', context_name: 'Study 1', id: '1' },
  messages: [{ id: '1', content: 'test message', role: 'user' }],
  ...params
});

export const buildMeetingProviders = () => [
  {
    name: 'Google Meet',
    logo_path: '/temp-assets/google-meet-24x24.png',
    connected: true,
    tooltip: '',
    video_url_type: 'google_meet'
  },
  {
    name: 'Microsoft Teams',
    logo_path: '/temp-assets/microsoft-teams-24x24.png',
    connected: true,
    connected_account_id: 1,
    tooltip: '',
    video_url_type: 'microsoft_teams'
  },
  {
    name: 'Zoom',
    logo_path: '/temp-assets/zoom-24x24.png',
    connected: true,
    tooltip: '',
    video_url_type: 'zoom'
  },
  {
    name: 'In Person',
    logo_path: '',
    connected: true,
    tooltip: '',
    video_url_type: 'in_person'
  },
  {
    name: 'Custom link',
    logo_path: '',
    connected: true,
    tooltip: '',
    video_url_type: 'manual'
  }
];

export const buildBackgroundTask = (params?: Partial<BackgroundTask>): BackgroundTask => ({
  id: 1,
  action: 'invite',
  status: 'pending',
  created_at: '2023-11-27T18:38:47.685+02:00',
  ...params
});

export const buildRepoSessionLiveStream = (params?: Partial<RepoSessionsLiveStream>): RepoSessionsLiveStream => ({
  id: 1,
  status: 'scheduled',
  playback_url: 'https://playback.url',
  message: 'message',
  temp_transcript_document_id: 1,
  repo_session_id: 1,
  ...params
});
